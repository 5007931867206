import {
  UseSkinTrackerVariantInput,
  UseSkinTrackerVariantResult,
  Variant,
} from './types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@customer-frontend/auth';
import { getEntryWeekLabel } from './utils';
import { ReactComponent as Tick } from 'assets/icons/Tick.svg';
import consultPaidBeforeSoftwareImage from 'assets/progress-tracker/preview/consult-paid-before-software.png';
import consultPaidDuringSoftwareImage from 'assets/progress-tracker/preview/consult-paid-during-software.png';
import treatmentBeforeSoftwareImage from 'assets/progress-tracker/preview/treatment-before-software.png';
import treatmentDuringSoftwareImage from 'assets/progress-tracker/preview/treatment-during-software.png';
import { useStartTrackerQuiz } from 'utils/hooks/use-start-tracker-quiz';
import { progressTrackerRoutes } from 'utils/routes';
import moment from 'moment';

export const useSkinTrackerVariant = ({
  treatmentStartDate,
  progressEntries,
  variant,
}: UseSkinTrackerVariantInput): UseSkinTrackerVariantResult => {
  const history = useHistory();
  const { loggedInUser } = useAuth();
  const startTrackerQuiz = useStartTrackerQuiz();

  const sortedEntriesDesc = [...progressEntries].sort((a, b) =>
    moment(b.date).diff(moment(a.date)),
  );

  return React.useMemo<UseSkinTrackerVariantResult>(() => {
    switch (variant) {
      case Variant.ConsultationPaidPage: {
        let description: React.ReactElement | null = (
          <>Let’s upload your ‘before’ photos.</>
        );
        let photoTitles = ['Before software', 'During software'];
        if (sortedEntriesDesc.length) {
          description = null;
        }
        if (sortedEntriesDesc.length > 1) {
          photoTitles = ['Before software', 'Week 1'];
        }

        return {
          title: 'Welcome to your skin progress tracker',
          photoTitles,
          previewPhotos: [
            consultPaidBeforeSoftwareImage,
            consultPaidDuringSoftwareImage,
          ],
          checklist: (
            <>
              <p>Your ‘before’ photo will:</p>
              <ul>
                {[
                  'Track your ‘before’ and ‘after’ progress',
                  'Enable the practitioner to see your progress overtime',
                  'Inform the practitioner when adjusting your formula',
                ].map((item) => (
                  <li className="flex mb-2" key={item}>
                    <span className="mr-1">
                      <Tick height="24px" width="24px" />
                    </span>
                    {item}
                  </li>
                ))}
              </ul>
            </>
          ),
          description,
          startSkinQuiz: () =>
            startTrackerQuiz('consultation_paid', 'SKIN_GENERAL'),
        };
      }

      case Variant.TreatmentPage: {
        let photoTitles = ['Before software', 'Week 1'];
        let description: React.ReactElement | null = (
          <>Hi {loggedInUser?.firstName}, how is your skin going today?</>
        );
        if (sortedEntriesDesc.length === 1) {
          description = (
            <>
              Your photos are in the{' '}
              <span
                className="font-semibold cursor-pointer underline"
                onClick={() => history.push(progressTrackerRoutes.root)}
              >
                skin progress
              </span>{' '}
              tracker. It’s recommended that you add more photos as you use your
              Software.
            </>
          );
        }
        if (sortedEntriesDesc.length > 1) {
          photoTitles = [
            'Before software',
            getEntryWeekLabel(sortedEntriesDesc[0], treatmentStartDate),
          ];
        }

        return {
          title: 'Your skin progress',
          photoTitles,
          previewPhotos: [
            treatmentBeforeSoftwareImage,
            treatmentDuringSoftwareImage,
          ],
          description,
          startSkinQuiz: () => startTrackerQuiz('treatment', 'SKIN_GENERAL'),
          checklist: null,
          showHelperModal: true,
        };
      }

      default:
        throw new Error(
          `useSkinTrackerVariant not implemented for variant ${variant}`,
        );
    }
  }, [
    variant,
    sortedEntriesDesc,
    startTrackerQuiz,
    loggedInUser?.firstName,
    history,
    treatmentStartDate,
  ]);
};
