import kinTheme from './kin';
import pilotTheme from './pilot';
import softwareTheme from './software';
import juniperTheme from './juniper';
import { Brand } from '@customer-frontend/types';
import { BrandTheme } from './types';
import * as sharedColors from './shared/palette';
import {
  ChakraTheme,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';

const makeChakraTheme = ({
  colors,
  components,
  extensions,
}: BrandTheme): ChakraTheme => {
  const theme = extendTheme(
    {
      colors: { ...colors, ...sharedColors },
      components,
      extensions,
      styles: () => ({
        global: (): Record<string, string> => ({}),
      }),
    },
    withDefaultColorScheme({ colorScheme: 'brand' }),
  );
  return theme as ChakraTheme; // Investigate why this cast is necessary
};

export const getBrandTheme = (brand: Brand): ChakraTheme => {
  switch (brand) {
    case 'pilot':
      return makeChakraTheme(pilotTheme);
    case 'kin':
      return makeChakraTheme(kinTheme);
    case 'software':
      return makeChakraTheme(softwareTheme);
    case 'compound-uk': // TODO: https://linear.app/eucalyptus/issue/ENG-1789/%5Bcustomer-frontend%5D-compound-design-system
    case 'juniper':
    case 'juniper-uk':
    case 'juniper-de':
    case 'juniper-jp':
      return makeChakraTheme(juniperTheme);
  }
};
