import clsx from 'clsx';
import React from 'react';
import { ProgressChecklistItemProps, Variant } from './types';
import { ReactComponent as CircleTick } from 'assets/icons/circle-tick.svg';
import { yellow100 } from '@eucalyptusvc/design-system/src/theme/software/palette';

const getItemMarkerClass = (classNames = ''): string =>
  clsx(classNames, 'absolute');

const CompletedMarker: React.FunctionComponent = () => {
  return (
    <CircleTick
      width={'1.94rem'}
      height={'1.94rem'}
      className={getItemMarkerClass('bg-white')}
    />
  );
};

const IncompleteMarker: React.FunctionComponent = () => {
  return (
    <span
      className={getItemMarkerClass(
        'rounded-full border border-solid border-black w-1.94 h-1.94 inline-block',
      )}
      style={{ background: yellow100 }}
    />
  );
};

const DisabledMarker: React.FunctionComponent = () => {
  return (
    <span
      className={getItemMarkerClass(
        'rounded-full border border-solid border-grey-300 bg-white w-1.94 h-1.94 inline-block',
      )}
    />
  );
};

const getItemBorderClass = (variant: Variant, classNames = ''): string =>
  clsx(
    classNames,
    'absolute border-l left-0.94 border-l-solid bottom-0 h-1/2 inline-block',
    variant === 'disabled' ? 'border-gray-300' : 'border-black',
  );

export const ProgressChecklistItem: React.FunctionComponent<
  ProgressChecklistItemProps
> = ({ children, currentItem, previousItem, nextItem }) => {
  const ChecklistMarker = React.useMemo(() => {
    switch (currentItem.variant) {
      case 'completed':
        return CompletedMarker;
      case 'incomplete':
        return IncompleteMarker;
      case 'disabled':
        return DisabledMarker;
      default:
        throw new Error(
          `No checklist marker for variant ${currentItem.variant}`,
        );
    }
  }, [currentItem]);

  return (
    <li
      className={clsx(
        'flex items-center relative',
        previousItem && 'pt-4',
        nextItem && 'pb-4',
      )}
    >
      {previousItem && (
        <span
          className={getItemBorderClass(
            currentItem.variant,
            'transform -translate-y-full',
          )}
          aria-hidden
        />
      )}
      {nextItem && (
        <span className={getItemBorderClass(nextItem.variant)} aria-hidden />
      )}
      <ChecklistMarker />
      <span className="ml-11 flex-grow">{children}</span>
    </li>
  );
};
