import { Typography } from '@eucalyptusvc/design-system';
import { ReactComponent as FaceSmiley } from 'assets/icons/face-smiley.svg';
import { ReactComponent as ChartArrowUp } from 'assets/icons/chart-arrow-up.svg';
import { ReactComponent as ChartArrowDown } from 'assets/icons/chart-arrow-down.svg';
import { ReactComponent as Like } from 'assets/icons/like.svg';

const quizSummaryMap: {
  [key: string]: { icon: React.ReactElement; title: string; text: string };
} = {
  ON_TRACK: {
    icon: <FaceSmiley className="mr-2" />,
    title: 'You’re making good progress',
    text: "You're seeing results with no side effects - that's excellent news!",
  },
  KEEP_IT_UP: {
    icon: <ChartArrowUp className="mr-2" />,
    title: 'You’re making good progress',
    text: "No side effects is something to celebrate! Meanwhile, it's important to remember that results can take time.",
  },
  OFF_TRACK: {
    icon: <ChartArrowDown className="mr-2" />,
    title: 'We need to get you back on track',
    text: 'It sounds like things could be better. Our team is here to intervene and get you closer to you skin goals',
  },
  PROGRESSING_NORMALLY: {
    icon: <Like className="mr-2" />,
    title: "You're progressing normally ",
    text: "At this early stage, it's normal to experience side effects. Simple interventions can help you manage",
  },
};

export const CheckInQuizOutcome = ({
  quizSummary,
  hideSubHeading = false,
}: {
  quizSummary: string;
  hideSubHeading?: boolean;
}): React.ReactElement => {
  const values = quizSummaryMap[quizSummary];

  return (
    <>
      <div className="flex items-center">
        {values.icon}
        <Typography size="large-paragraph">{values.title}</Typography>
      </div>
      {!hideSubHeading && (
        <Typography size="small-text">{values.text}</Typography>
      )}
    </>
  );
};
