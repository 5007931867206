import {
  ApolloClient,
  gql,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import debounce from 'lodash.debounce';
import {
  Maybe,
  Address,
  UpdateShippingProfileAccountMutation,
  UpdateShippingProfileAccountMutationVariables,
} from '@customer-frontend/graphql-types';
import {
  Card,
  Button,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getConfig } from '@customer-frontend/config';
import { FormattedMessage, useIntl } from 'react-intl';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import {
  AddressFields,
  IntlAddressInput,
  IntlAddressView,
} from '@customer-frontend/intl';
import { ShippingValidationStatus } from '@customer-frontend/order';

interface FormFields {
  shippingAddress: AddressFields;
  shippingAddressProximity?: 'inRange' | 'outOfRange';
}

const updateShippingDocument = gql`
  mutation UpdateShippingProfileAccount(
    $address: AddressCreateWithoutUserInput!
  ) {
    updateShipping(address: $address) {
      id
      address {
        id
        line1
        line2
        city
        postalCode
        prefecture
        municipality
        townArea
        state
        country
        building
        company
      }
    }
  }
`;

const validateShippingAddressRange = debounce(
  async (
    shippingAddress: AddressFields,
    apollo: ApolloClient<unknown>,
    onComplete: (p: FormFields['shippingAddressProximity']) => void,
  ) => {
    const line1 = shippingAddress?.line1;
    const line2 = shippingAddress?.line2;
    const suburb = shippingAddress?.city;
    const postcode = shippingAddress?.postalCode;

    if (!(line1 && postcode && suburb)) {
      return;
    }

    const query = await apollo.query({
      query: gql`
        query JuniperProfileShippingDetailsRange(
          $line1: String!
          $line2: String
          $suburb: String!
          $postcode: String!
        ) {
          shippingAddressWithinRange(
            line1: $line1
            line2: $line2
            postcode: $postcode
            suburb: $suburb
          )
        }
      `,
      variables: {
        line1,
        line2,
        suburb,
        postcode,
      },
    });

    onComplete(
      query.data.shippingAddressWithinRange ? 'inRange' : 'outOfRange',
    );
  },
  1000,
);

export const RangeShippingDetails = ({
  address,
  onUpdated,
  shouldValidateShippingAddressRange = false,
  enableEditing = false,
}: {
  address: Maybe<Address>;
  onUpdated?: () => void;
  shouldValidateShippingAddressRange: boolean;
  enableEditing?: boolean;
}): React.ReactElement => {
  const config = getConfig();
  const apollo = useApolloClient();
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const [validationLoading, setValidationLoading] = useState(enableEditing);

  const [isEditing, setIsEditing] = useState(
    !address || !address?.line1 || enableEditing,
  );

  const { register, handleSubmit, errors, setValue, watch, formState } =
    useForm<FormFields>({
      defaultValues: {
        shippingAddress: {
          line1: address?.line1,
          line2: address?.line2,
          city: address?.city,
          postalCode: address?.postalCode,
          prefecture: address?.prefecture,
          municipality: address?.municipality,
          townArea: address?.townArea,
          state: address?.state,
          country: config.country,
          company: address?.company,
          building: address?.building,
        },
        shippingAddressProximity: 'inRange',
      },
      shouldUnregister: false,
    });

  const [updateShipping] = useMutation<
    UpdateShippingProfileAccountMutation,
    UpdateShippingProfileAccountMutationVariables
  >(updateShippingDocument, {
    onCompleted: () => {
      setIsEditing(false);
      onUpdated?.();
    },
  });

  const shippingAddress = watch('shippingAddress');
  const shippingAddressProximity = watch('shippingAddressProximity');
  const isShippingAddressWithinRange = shippingAddressProximity === 'inRange';

  return (
    <Card>
      <div className="flex align-start justify-between">
        <Typography isBold size="md">
          <FormattedMessage
            defaultMessage="Shipping details"
            description="Title of shipping details settings section"
          />
        </Typography>
        {!isEditing && (
          <span>
            <Button level="secondary" onClick={() => setIsEditing(true)}>
              <FormattedMessage
                defaultMessage="Edit"
                description="Button to edit details"
              />
            </Button>
          </span>
        )}
      </div>
      {isEditing ? (
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4"
          onSubmit={handleSubmit(async (data) => {
            try {
              await updateShipping({
                variables: {
                  address: {
                    line1: data.shippingAddress.line1 || null,
                    line2: data.shippingAddress.line2 || null,
                    city: data.shippingAddress.city || null,
                    postalCode: data.shippingAddress.postalCode || null,
                    state: data.shippingAddress.state || null,
                    prefecture: data.shippingAddress.prefecture || null,
                    municipality: data.shippingAddress.municipality || null,
                    townArea: data.shippingAddress.townArea || null,
                    company: data.shippingAddress.company || null,
                    building: data.shippingAddress.building || null,
                    country: data.shippingAddress.country || config.country,
                  },
                },
                context: {
                  skipErrorNotification: true,
                },
              });
            } catch (err) {
              const descriptions = getErrorMessageDescriptorsFromError(err);
              descriptions.forEach((descriptor) =>
                notify.error({ message: formatMessage(descriptor) }),
              );
            }
          })}
        >
          <div className="col-span-1 md:col-span-2">
            <IntlAddressInput
              className="mt-3"
              name="shippingAddress"
              useAutoComplete
              registerField={register}
              errors={errors.shippingAddress ?? {}}
              autoFocus={enableEditing}
              onChange={async (address) => {
                if (shouldValidateShippingAddressRange) {
                  setValidationLoading(true);
                  await validateShippingAddressRange(address, apollo, (p) => {
                    setValue('shippingAddressProximity', p);
                    setValidationLoading(false);
                  });
                }
              }}
            />
            {shouldValidateShippingAddressRange && (
              <ShippingValidationStatus
                isResidential={false}
                isValidatingShippingAddress={validationLoading}
                isShippingAddressCompleted={!!shippingAddress?.postalCode}
                isShippingAddressWithinRange={isShippingAddressWithinRange}
              />
            )}
          </div>

          <div className="grid grid-cols-2 gap-4 col-span-1 md:col-span-2">
            <div>
              {address && (
                <Button
                  isFullWidth
                  level="secondary"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Cancel"
                    description="Button that cancels action"
                  />
                </Button>
              )}
            </div>
            <Button
              isSubmit
              isFullWidth
              isLoading={formState.isSubmitting}
              isDisabled={!isShippingAddressWithinRange || validationLoading}
            >
              <FormattedMessage
                defaultMessage="Save"
                description="Button that saves details"
              />
            </Button>
          </div>
        </form>
      ) : (
        <IntlAddressView address={address} />
      )}
    </Card>
  );
};
