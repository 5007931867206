import {
  Button,
  Modal,
  Typography,
  useResponsive,
} from '@eucalyptusvc/design-system';
import { ReactComponent as Tick } from 'assets/tick-v2.svg';
import { ReactElement, useState } from 'react';
import progressImage from 'assets/progress-tracker/skin-tracker-modal-timeline.png';

export const modalDotPoints = [
  'Enable the practitioner to see your progress.',
  'Track any improvements or side effects such as ‘purging’.',
  'Inform the practitioner when adjusting your formula to suit your new, evolving skin.',
];

export const WhatIsThisModalButton = (): ReactElement => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const toggleShowModal = (): void => {
    setShowModal(!showModal);
  };

  const { isMobile } = useResponsive();

  return (
    <>
      <div className="flex static sm:absolute sm:top-16 sm:right-0 sm:mt-2">
        <Button
          eventElementName="skinTrackerWhatIsThisModalButton"
          onClick={toggleShowModal}
          level="secondary"
          isFullWidth
          size={isMobile ? 'md' : 'sm'}
        >
          What is this?
        </Button>
      </div>

      <Modal
        title="Track your skin progress"
        isOpen={showModal}
        onClose={toggleShowModal}
      >
        <div className="space-y-4 mb-4">
          <Typography size="medium-paragraph">
            The skin tracker is designed for your personal tracking and for your
            consultation assessment.
          </Typography>
          <img
            src={progressImage}
            className="mx-auto"
            alt="example timeline of skin progress over 26 weeks"
          />
          <Typography size="medium-paragraph">
            Tracking your skin progress will help to monitor the effectiveness
            of your treatment.
          </Typography>
          <ul className="space-y-2">
            {modalDotPoints.map((item) => (
              <li className="flex space-x-2" key={item}>
                <Tick height="24px" width="24px" />
                <Typography size="medium-paragraph">{item}</Typography>
              </li>
            ))}
          </ul>
        </div>

        <Button
          eventElementName="skinTrackerWhatIsThisModalBackButton"
          onClick={toggleShowModal}
          level="secondary"
          isFullWidth
        >
          Back
        </Button>
      </Modal>
    </>
  );
};
