import { ProgressEntryAnswerInput } from '@customer-frontend/graphql-types';
import React, { ReactElement } from 'react';
import { ProgressEntryProps } from './types';
import {
  getAnswerByQuestionCode,
  getImageFromBufferOrUrl,
  photoResponseOrder,
} from './utils';
import { SKIN_CONFIDENCE_ANSWERS } from './content';
import { ProgressEntryImage } from './common/entry-images';
import { Typography } from '@eucalyptusvc/design-system';

const OrderedImages = ({
  answers,
}: {
  answers: ProgressEntryAnswerInput[];
}): ReactElement => {
  const images: ReactElement[] = [];

  photoResponseOrder.forEach((questionCode) => {
    const matchingResponse = getAnswerByQuestionCode(answers, questionCode);
    if (matchingResponse?.type === 'PHOTO') {
      const imageId = getImageFromBufferOrUrl(matchingResponse.answer);

      images.push(
        <ProgressEntryImage key={matchingResponse.answer} imageId={imageId} />,
      );
    }
  });
  return <> {images} </>;
};

export const ProgressEntry = ({
  date,
  answers,
}: ProgressEntryProps): ReactElement => {
  const skinResponse = getAnswerByQuestionCode(answers, 'SKIN_CONFIDENCE');
  const resp = skinResponse?.answer
    ? SKIN_CONFIDENCE_ANSWERS[Number(skinResponse.answer)]
    : null;

  return (
    <div className="grid text-left space-y-1">
      <Typography size="paragraph">
        <span className="uppercase">{date}</span>
      </Typography>
      {resp && (
        <Typography size="medium-paragraph">
          Skin was feeling <strong>{resp.answer.toLowerCase()}</strong>{' '}
          {resp.emoji}
        </Typography>
      )}
      <div className="flex w-full justify-between pt-2 space-x-1">
        <OrderedImages answers={answers} />
      </div>
    </div>
  );
};

export default ProgressEntry;
