import React, { ReactElement, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RefetchQueriesInclude, gql, useMutation } from '@apollo/client';
import { addDays, isAfter } from 'date-fns';
import {
  Button,
  Modal,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { formatCentsToCurrency } from '@customer-frontend/order';
import { useEnvironment } from '@customer-frontend/environment';
import {
  ProblemType,
  PurchasePausedCardPurchaseFragment,
  ResumePurchaseMutation,
  ResumePurchaseMutationVariables,
} from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { ConsultationCardContentView } from '../consultation/components/consultation-card-content-view';
import { PrescriberType } from '@customer-frontend/doctor';

const resumePurchaseMutation = gql`
  mutation ResumePurchase($input: ResumePurchaseInput!) {
    resumePurchase(input: $input) {
      purchase {
        id
        status
        isVisibleInProfilePage
        availableActions {
          id
        }
      }
    }
  }
`;

type ResumePurchaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  chargeAmount: number;
  purchaseId: string;
  nextOrderDate: Date;
  refetchQueries?: RefetchQueriesInclude;
  problemType?: ProblemType | null;
};

const ResumePurchaseModal = ({
  isOpen,
  onClose,
  chargeAmount,
  purchaseId,
  nextOrderDate,
  refetchQueries,
  problemType,
}: ResumePurchaseModalProps): React.ReactElement => {
  const { formatMessage, formatDate } = useIntl();
  const notify = useNotification();
  const { brand, prescriberType } = getConfig();
  const [resumePurchase, { loading }] = useMutation<
    ResumePurchaseMutation,
    ResumePurchaseMutationVariables
  >(resumePurchaseMutation, {
    refetchQueries,
    onCompleted: () => {
      notify.success({
        message: formatMessage({
          defaultMessage: 'Your treatment has been resumed.',
          description:
            'Success message on profile after the user selects to resume their purchase',
        }),
      });
      onClose();
    },
    onError: () => {
      notify.error({
        message: formatMessage({
          defaultMessage:
            'Your request to resume your treatment has failed. Please try again.',
          description:
            'Error message when user attempts to resume their purchase and fails',
        }),
      });
    },
    context: {
      skipErrorNotification: true,
    },
  });
  const showNewResumeCopy =
    brand === 'juniper' ||
    brand === 'juniper-uk' ||
    (brand === 'pilot' && problemType === 'WEIGHT_LOSS');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography isBold size="md">
          <FormattedMessage
            defaultMessage="Resume treatment"
            description="Modal title for confirming resuming purchase in profile"
          />
        </Typography>
      }
    >
      <div className="space-y-4">
        <Typography size="medium-paragraph">
          {showNewResumeCopy ? (
            <FormattedMessage
              defaultMessage="<strong>If you paused less than 3 weeks ago</strong>{br}Click the button below to automatically resume treatment. You will be charged <strong>{amount}</strong> for your next order {hasNextOrderDate, select, true {on <strong>{date}</strong> and it will be on it’s way to you after this date} other {and it will be on it’s way to you shortly}}."
              description="Text in profile to show how much the user will be charged in the future"
              values={{
                br: '\n',
                amount: formatCentsToCurrency(chargeAmount),
                strong: (chunks) => <strong>{chunks}</strong>,
                date: formatDate(nextOrderDate),
                hasNextOrderDate: isAfter(
                  nextOrderDate,
                  addDays(Date.now(), 1),
                ),
              }}
            />
          ) : isAfter(nextOrderDate, addDays(Date.now(), 1)) ? (
            <FormattedMessage
              defaultMessage="If you paused your treatment less than 3 weeks ago, your treatment will resume and you will be charged <strong>{amount}</strong> on the <strong>{date}</strong>."
              description="Text in profile to show how much the user will be charged in the future"
              values={{
                amount: formatCentsToCurrency(chargeAmount),
                strong: (chunks) => <strong>{chunks}</strong>,
                date: formatDate(nextOrderDate),
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="If you paused your treatment less than 3 weeks ago, your treatment will resume and you will be charged <strong>{amount}</strong>."
              description="Text in profile to show how much the user will be charged now"
              values={{
                amount: formatCentsToCurrency(chargeAmount),
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          )}
        </Typography>
        <Typography size="medium-paragraph">
          {showNewResumeCopy ? (
            <FormattedMessage
              defaultMessage="<strong>If you paused more than 3 weeks ago</strong>{br}A free review consult with a {isPrescriber, select, true {prescriber} other {practitioner}} is required to determine if your treatment plan needs adjustment. Click the button below to complete a short pre-consult quiz, before we connect you with a practitioner."
              description="Text in profile to show what will happen if the user paused more than 3 weeks ago"
              values={{
                br: '\n',
                strong: (chunks) => <strong>{chunks}</strong>,
                isPrescriber: prescriberType === PrescriberType.PRESCRIBER,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="If you paused your treatment more than 3 weeks ago, you will require a free follow-up consult with a prescriber to assess whether your treatment needs to change. You'll need to complete the quiz to be assigned a prescriber."
              description="Text in profile to show what will happen if the user paused more than 3 weeks ago"
            />
          )}
        </Typography>
        <Button
          isSubmit
          isFullWidth
          isLoading={loading}
          onClick={() => {
            resumePurchase({
              variables: {
                input: {
                  purchaseId,
                },
              },
            });
          }}
        >
          <FormattedMessage
            defaultMessage="Confirm"
            description="Button text to confirm resuming purchase in profile"
          />
        </Button>
      </div>
    </Modal>
  );
};

type PurchasePausedCardProps = {
  purchase: PurchasePausedCardPurchaseFragment;
  refetchQueries?: RefetchQueriesInclude;
  children: React.ReactNode;
};

export function PurchasePausedCard({
  purchase,
  refetchQueries,
  children,
}: PurchasePausedCardProps): ReactElement | null {
  const environment = useEnvironment();
  const [showModal, setShowModal] = useState(false);
  const config = getConfig();
  const contactUrl = `${environment.landingPageUrl}${config.urlPaths.contact}`;

  if (!purchase.offering) {
    return null;
  }

  const isPurchaseResumeable = purchase.availableActions?.some(
    (a) => a.__typename === 'PurchaseResumeAction',
  );

  if (!isPurchaseResumeable || !purchase.nextOrder) {
    return (
      <ConsultationCardContentView
        paragraphs={[
          {
            key: 'purchasePausedCardText',
            text: (
              <FormattedMessage
                defaultMessage="You've currently paused your treatment. To resume your treatment, please <a> contact our support team. </a>"
                description="Text informing users the status of their treatment"
                values={{
                  a: (chunks: React.ReactNode[]) => (
                    <a href={contactUrl} className="text-link text-primary-400">
                      {chunks}
                    </a>
                  ),
                }}
              />
            ),
          },
        ]}
        buttons={[]}
      >
        {children}
      </ConsultationCardContentView>
    );
  }

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'purchasePausedCardText',
          text: (
            <FormattedMessage
              defaultMessage="You've currently paused your treatment."
              description="Text informing users the status of their purchase"
            />
          ),
        },
      ]}
      buttons={[
        {
          key: 'purchasePausedCardButton',
          text: (
            <FormattedMessage
              defaultMessage="Resume Treatment"
              description="Button on the profile to resume a paused purchase"
            />
          ),
          onClick: () => {
            setShowModal(true);
          },
          level: 'secondary' as const,
          isDisabled: showModal,
        },
      ]}
    >
      {children}
      <ResumePurchaseModal
        isOpen={showModal}
        purchaseId={purchase.id}
        onClose={() => setShowModal(false)}
        chargeAmount={purchase.nextOrder.totalAmount}
        nextOrderDate={new Date(purchase.nextOrder.date)}
        problemType={purchase.problemType}
        refetchQueries={refetchQueries}
      />
    </ConsultationCardContentView>
  );
}

PurchasePausedCard.fragment = gql`
  fragment PurchasePausedCardPurchase on Purchase {
    id
    problemType
    offering {
      id
    }
    availableActions {
      id
    }
    nextOrder {
      id
      date
      totalAmount
    }
  }
`;
