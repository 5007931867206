import { Typography } from '@eucalyptusvc/design-system';
import { useOTCCart } from '@customer-frontend/order';
import { ReactComponent as ShoppingCart } from 'assets/shopping-cart.svg';

export const ShoppingCartButton = (): React.ReactElement => {
  const { cartItems, isOTCCartOpen, setIsOTCCartOpen } = useOTCCart();
  return (
    <span
      className="cursor-pointer"
      onClick={() => setIsOTCCartOpen(!isOTCCartOpen)}
    >
      {!!cartItems.length && (
        <span className="ml-4 -mt-1.5 w-4 h-4 flex flex-col bg-beige-200 rounded-full absolute">
          <Typography textAlign="center" size="small-text">
            {cartItems.length}
          </Typography>
        </span>
      )}
      <ShoppingCart />
    </span>
  );
};
