import { FormattedMessage } from 'react-intl';
import { ConsultationCardContentView } from '../consultation/components/consultation-card-content-view';

export const PurchaseAwaitingPaymentCard: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'purchaseProcessingText',
          text: (
            <FormattedMessage
              defaultMessage="You will be notified when your order is on its way. Sit tight!"
              description="Description telling users that they will be notified of any updates to their order"
            />
          ),
        },
      ]}
      buttons={[]}
    >
      {children}
    </ConsultationCardContentView>
  );
};
