import { SkinGoal } from '@customer-frontend/graphql-types';
import { AppEnv } from '@customer-frontend/types';
import {
  blue,
  green,
  mauve,
  red,
  yellow,
} from '@eucalyptusvc/design-system/src/theme/software/palette';

import BlueBlob from 'assets/blobs/blue-blob.gif';
import RedBlob from 'assets/blobs/red-blob.gif';
import YellowBlob from 'assets/blobs/yellow-blob.gif';

export const ESSENTIALS_SKU = 'OTCSTWESSETV2';

export const CLEANSER_SKU = 'OTCDLYCLNSV2';
export const MOISTURISER_SKU = 'OTCDLYMSTV2';
export const SOLAR_FLUID_SKU = 'OTCDLYSUNSDEFSPF50';

export type ProductIDRecommendationConfig = {
  ids: Partial<Record<AppEnv, string>>;
  strikeThroughPrice?: string;
  bundleItems?: string[];
  containsActiveIngredients?: boolean;
};

export type SkinGoalPersonalisationOption = {
  color: string;
  letter?: string;
  shortText?: string;
  longText?: string;
  graphic?: string;
  recommendations?: ProductIDRecommendationConfig[];
};

const essentialsBundleConfig: ProductIDRecommendationConfig = {
  ids: {
    // Essentials bundle
    development: 'ckq1r9y8e0167f8mhu6zi8c8e',
    production: 'clk98torj0002sa01j763r1vd',
  },
  bundleItems: ['Cleanser', 'Solar Fluid (SPF50+)', 'Moisturiser'],
};

const acneRecommendations: ProductIDRecommendationConfig[] = [
  {
    ids: {
      // Acne kit
      development: 'cl82kdvk901010szuwvnti8zy',
      production: 'cl6uddh7j86051ax0zhd58b61',
    },
    strikeThroughPrice: '$152',
    bundleItems: [
      'Acne supplement',
      'Salicylic Acid Foaming Wash',
      'Ceramide Repair Balm',
      'Pimple Patches',
    ],
  },
  essentialsBundleConfig,
];

const ageingRecommendations: ProductIDRecommendationConfig[] = [
  {
    ids: {
      // Ageing set
      development: 'ckq1r69z90053fomhkehnqb4d',
      production: 'ckzyqevpd8172onrwzb9orchy',
    },
    containsActiveIngredients: true,
    bundleItems: [
      'Vitamin C + Ferulic Serum',
      'Retinol Complex Oil',
      'Salicylic Acid Foaming Wash',
      'Ceramide Repair Balm',
      'Solar fluid',
      'Headband',
      'Carry case',
    ],
  },
  essentialsBundleConfig,
];

const textureRecommendations: ProductIDRecommendationConfig[] = [
  essentialsBundleConfig,
  {
    ids: {
      // Vitamin C
      development: 'ckzq12voc0003qkmizbd6xtzp',
      production: 'clbu89fqb1289780swittj7xwtw',
    },
    containsActiveIngredients: true,
  },
];

export const SKIN_GOAL_PERSONALISATION_OPTIONS: Record<
  SkinGoal,
  SkinGoalPersonalisationOption
> = {
  BrightenSkin: {
    color: green,
    letter: 'd',
    shortText: 'dullness',
    longText: 'brighten skin',
    graphic: BlueBlob,
    recommendations: ageingRecommendations,
  },
  FadePigmentation: {
    color: yellow,
    letter: 'p',
    shortText: 'pigmentation',
    longText: 'fade pigmentation (including melasma)',
    graphic: YellowBlob,
    recommendations: textureRecommendations,
  },
  ReduceBlackheadsWhiteheads: {
    color: red,
    letter: 'b',
    shortText: 'blackheads',
    longText: 'reduce blackheads / whiteheads',
    graphic: RedBlob,
    recommendations: acneRecommendations,
  },
  ReducePimples: {
    color: red,
    letter: 'p',
    shortText: 'pimples',
    longText: 'reduce pimples',
    graphic: RedBlob,
    recommendations: acneRecommendations,
  },
  ReduceRedness: {
    color: mauve,
    letter: 'r',
    shortText: 'redness',
    longText: 'reduce redness',
    graphic: RedBlob,
    recommendations: textureRecommendations,
  },
  RemoveFineLinesWrinkles: {
    color: green,
    letter: 'f',
    shortText: 'fine lines',
    longText: 'remove fine lines / wrinkles',
    graphic: BlueBlob,
    recommendations: ageingRecommendations,
  },
  TreatAcneScarring: {
    color: red,
    letter: 'a',
    shortText: 'acne',
    longText: 'treat acne scarring',
    graphic: RedBlob,
    recommendations: acneRecommendations,
  },
  TreatDrySkin: {
    color: blue,
    letter: 'd',
    shortText: 'dryness',
    longText: 'treat dry skin',
    graphic: BlueBlob,
    recommendations: textureRecommendations,
  },
};
