import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider, authService } from 'services/auth';
import { ClientProvider } from '@customer-frontend/graphql-client';
import { StripeProvider } from 'services/stripe';
import 'styles/tailwind.generated.css';
import 'styles/chat.css';
import Pages from 'pages';
import { Loading } from 'components/presentational/loading';
import { FeatureFlagProvider } from '@customer-frontend/feature-flags';
import { ErrorBoundary } from 'services/error-boundary';
import { DesignSystemProvider } from '@eucalyptusvc/design-system';
import { EventServiceProvider } from '@customer-frontend/events';
import { EnvironmentProvider } from '@customer-frontend/environment';
import { OfflineWrapper } from '@customer-frontend/offline';
import { I18nProvider } from '@customer-frontend/i18n';
import { getConfig } from '@customer-frontend/config';
import { environment } from 'utils/environment';
import { eventService } from 'services/event';
import { logger } from 'utils/logging';
import { notificationService } from '@customer-frontend/notifications';
import { utmService } from '@customer-frontend/utm';
import Bottle from 'assets/bottle.svg';
import { routes } from 'utils/routes';
import { ChatProvider } from '@customer-frontend/chat-provider';
import './utils/rum';

const config = getConfig();

notificationService.init({ brand: config.brand });
utmService.saveUtmsToCookie();

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = createRoot(rootElement);

root.render(
  <EnvironmentProvider {...environment}>
    <DesignSystemProvider brand={config.brand}>
      <I18nProvider>
        <ClientProvider
          apiUrl={environment.apiUrl}
          appEnv={environment.appEnv}
          logger={logger}
          getToken={() => authService.getAccessToken()}
          onUnauthenticated={() => {
            authService.clear();
            window.location.href = routes.login;
          }}
        >
          <AuthProvider logger={logger}>
            <EventServiceProvider service={eventService}>
              <FeatureFlagProvider logger={logger}>
                <ChatProvider
                  streamAppKey={environment.streamAppKey || ''}
                  logger={logger}
                >
                  <OfflineWrapper brandName="Software" image={Bottle}>
                    <StripeProvider>
                      <ErrorBoundary>
                        <React.Suspense
                          fallback={
                            <div className="flex justify-center p-5 bg-beige-100 min-h-screen">
                              <Loading />
                            </div>
                          }
                        >
                          <Pages />
                        </React.Suspense>
                      </ErrorBoundary>
                    </StripeProvider>
                  </OfflineWrapper>
                </ChatProvider>
              </FeatureFlagProvider>
            </EventServiceProvider>
          </AuthProvider>
        </ClientProvider>
      </I18nProvider>
    </DesignSystemProvider>
  </EnvironmentProvider>,
);
