import {
  ProgressEntryAnswerType,
  ProgressEntryQuestionCodeType,
  ProgressEntry,
  ProgressEntryAnswerInput,
} from '@customer-frontend/graphql-types';
import moment from 'moment';
import { uiStorages } from '@customer-frontend/ui-storage';
import {
  PHOTO_TYPES,
  QUIZ_COPY,
  LAST_QUESTION,
  DEFAULT_PHOTO_ANGLE,
  DEFAULT_RESPONSE_OPTION,
  MASKED_DATE_FORMAT,
  getQuestionCodeFromAngle,
} from './content';
import { EntryWithWeek } from './types';
import { isBefore, isMatch, parse } from 'date-fns';

export const photoResponseOrder: ProgressEntryQuestionCodeType[] = [
  'PHOTO_LEFT',
  'PHOTO_MIDDLE',
  'PHOTO_RIGHT',
];

export const removeEmptyQuizResponses = (
  currentQuizResponses: ProgressEntryAnswerInput[],
): ProgressEntryAnswerInput[] => {
  return currentQuizResponses.filter((entry) => entry);
};

export const mapResponsesToAnswersWithImages = (
  currentQuizReponses: ProgressEntryAnswerInput[],
  imageMap: Record<string, string>,
): ProgressEntryAnswerInput[] => {
  return currentQuizReponses.map((answerObj) => {
    const isText = answerObj.type === 'RESPONSE';
    const answer = isText ? answerObj.answer : imageMap[answerObj.answer];

    return {
      ...answerObj,
      answer,
    };
  });
};

export const isValidProgressEntryDate = (
  date?: string,
): { isValid: boolean; errorMessage: string } => {
  if (!date) {
    return {
      isValid: false,
      errorMessage: 'Please enter a date for your entry',
    };
  }
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 1);
  // verify date format is DD/MM/YYYY
  if (!isMatch(date, MASKED_DATE_FORMAT)) {
    return {
      isValid: false,
      errorMessage: `Please enter a valid date in format ${MASKED_DATE_FORMAT.toLocaleUpperCase()}`,
    };
  }
  // verify date format is before tomorrow
  if (!isBefore(parse(date, MASKED_DATE_FORMAT, new Date()), maxDate)) {
    return {
      isValid: false,
      errorMessage: `Date can't be in the future`,
    };
  }

  // verify date had 2 digits for day, month and year. (mainly to check 4 digit year)
  if (date.length != 10) {
    return {
      isValid: false,
      errorMessage: 'Please enter a complete date',
    };
  }

  return {
    isValid: true,
    errorMessage: '',
  };
};

// standarised date for engine dates which needs a standarised timezone + time
export const getStandardisedDate = (date?: Date): Date => {
  const today = date ?? new Date();
  const standarisedDate = new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      today.getUTCHours(),
      today.getUTCMinutes(),
      today.getUTCSeconds(),
    ),
  );
  return standarisedDate;
};

export const groupEntriesByWeeks = (
  orderCompleteDate: Date,
  entries: ProgressEntry[],
): EntryWithWeek[] => {
  const weeksArray: EntryWithWeek[] = [];
  entries.forEach((entry) => {
    const weeks = getWeeksBetweenDates(orderCompleteDate, entry.date);
    const adjustedWeekValue = weeks < 0 ? 0 : weeks + 1; // entries before order complete go in index 0
    if (!weeksArray[adjustedWeekValue]) {
      weeksArray[adjustedWeekValue] = {
        entries: [],
        week: adjustedWeekValue,
      };
    }
    weeksArray[adjustedWeekValue].entries.push(entry);
  });
  return weeksArray.filter((entry) => entry);
};

export const getWeekLabel = (week: number): string => {
  return week > 0 ? `Week ${week}` : 'Before software';
};

export const getWeeksBetweenDates = (oldDate: Date, newDate: Date): number => {
  const duration = moment.duration(moment(newDate).diff(moment(oldDate)));
  const weeks = duration.asWeeks();
  return Math.round(weeks);
};

export const getAnswerTypefromQuestionCode = (
  questionCode: ProgressEntryQuestionCodeType,
): ProgressEntryAnswerType => {
  if (PHOTO_TYPES.includes(questionCode)) {
    return 'PHOTO';
  }
  return 'RESPONSE';
};

export const isLastQuestionByQuestionCode = (
  questionCode: ProgressEntryQuestionCodeType,
): boolean => {
  if (questionCode === LAST_QUESTION) {
    return true;
  }
  return false;
};

export const getAnswerByQuestionCode = (
  answers: ProgressEntryAnswerInput[],
  questionCode: ProgressEntryQuestionCodeType,
): ProgressEntryAnswerInput | undefined => {
  return answers.find((answer) => answer?.questionCode === questionCode);
};

export const progressEntryToProgressEntryAnswerInput = (
  entry: ProgressEntry,
): ProgressEntryAnswerInput[] => {
  if (entry === null || entry === undefined) {
    return [];
  }
  const transformedPhotoResponses: ProgressEntryAnswerInput[] = entry.photos
    ? entry.photos?.map((photoResponses) => {
        return {
          answer: photoResponses?.signedUrl ?? '',
          questionCode: getQuestionCodeFromAngle(
            photoResponses?.angle ?? DEFAULT_PHOTO_ANGLE,
          ),
          question:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            QUIZ_COPY[
              getQuestionCodeFromAngle(
                photoResponses?.angle ?? DEFAULT_PHOTO_ANGLE,
              )
            ].question,
          type: 'PHOTO',
        };
      })
    : [];
  const transformedTextResponses: ProgressEntryAnswerInput[] = entry.responses
    ? entry.responses?.map((textResponses) => {
        return {
          answer: textResponses?.answer ?? '',
          questionCode: textResponses?.questionCode ?? DEFAULT_RESPONSE_OPTION,
          question:
            QUIZ_COPY[textResponses?.questionCode ?? DEFAULT_RESPONSE_OPTION]
              .question,
          type: 'RESPONSE',
        };
      })
    : [];
  return [...transformedPhotoResponses, ...transformedTextResponses];
};

export const setLocalStorage = (key: string, item: string): void => {
  uiStorages.local.setValue(key, item);
};

export const getLocalStorage = (key: string): string | null => {
  return uiStorages.local.getValue(key) ?? null;
};

export const setLocalStorageWithExpiry = (
  key: string,
  item: string,
  expiry: number,
): void => {
  const today = new Date();
  const localStorageItem: LocalStorageWithExpiryType = {
    value: item,
    expiry: today.getTime() + expiry,
  };
  uiStorages.local.setValue(key, JSON.stringify(localStorageItem));
};

type LocalStorageWithExpiryType = {
  value: string;
  expiry: number;
};

type GetLocalStorageWithExpiryType = {
  item: string;
  isExpired: boolean;
};
export const getLocalStorageWithExpiry = (
  key: string,
): GetLocalStorageWithExpiryType => {
  const fetchedItem = uiStorages.local.getValue(key);
  const today = new Date();
  if (fetchedItem) {
    const jsonified: LocalStorageWithExpiryType = JSON.parse(fetchedItem);
    const isExpired = jsonified?.expiry < today.getTime();
    if (isExpired) {
      uiStorages.local.clearValue(key);
    }
    return {
      item: jsonified.value,
      isExpired: isExpired,
    };
  }
  return {
    item: 'NO VALUE',
    isExpired: false,
  };
};

export const getImageFromBufferOrUrl = (content: string): string => {
  try {
    return JSON.parse(content).fileBufferString;
  } catch {
    return content;
  }
};
