import { UserInfo } from '@customer-frontend/auth';
import { AppEnv } from '@customer-frontend/types';
import {
  ContactConsent,
  EEucalyptusEvent,
  EFacebookEvent,
  ESegmentDestination,
  IAccountCreatedEvent,
  ICalendarReminderAddedEvent,
  ICallRecordingConsentEvent,
  IChecklistGeneratedEvent,
  IConsultationCreatedEvent,
  IConsultationPaidEvent,
  IConsultationPaymentFailedEvent,
  IConsultationSurveyCompletedEvent,
  ICrossSellStartedEvent,
  ICustomerOfferingsPurchasedEvent,
  IDispatchQuizUnitEvent,
  IDoctorVideoEvent,
  IEducationArticleReadEvent,
  IEducationArticleViewedEvent,
  IEucalyptusEvent,
  IFeatureFlagExposedEvent,
  IFollowUpConsultationSurveyStarted,
  IFollowUpReminderClicked,
  IMedicareCollectionDeferredEvent,
  IMedicareCollectionIneligibleEvent,
  IMedicareFormatEvent,
  IOrderPaidEvent,
  IOtcPurchaseEvent,
  IOutOfRangeShippingAddressCapturedEvent,
  IPsatResponseEvent,
  IQuizStartedEvent,
  IQuizCompletedEvent,
  ISegmentEventDestination,
  IVideoPlayed,
  PafAddonsViewed,
  PafCartItemQuantityModified,
  SwitchOfferingSelected,
  UserResolver,
} from './types';
import { track, init, identify, page } from './segment';
import {
  DEFAULT_SEGMENT_EVENT_DESTINATION,
  SEGMENT_DESTINATION_WITH_KLAVIYO,
  SEGMENT_DESTINATION_WITH_ONLY_REDSHIFT,
  SEGMENT_DESTINATION_WITH_ONLY_REDSHIFT_AMPLITUDE_AND_KLAVIYO,
} from './constants';
import { IUIInteractionTracked } from './ui-interaction-tracked';
import { Logger } from '@customer-frontend/logger';
import { isSyntheticTest } from '@customer-frontend/utils';

declare const window: Window &
  typeof globalThis & {
    fbq?: (
      action: string,
      name: string,
      eventObj: Record<string, unknown>,
      eventProps: Record<string, unknown>,
    ) => void | undefined;
  };
export class EventService {
  constructor(
    private readonly userResolver: UserResolver,
    private readonly appEnv: AppEnv,
    private readonly logger: Logger,
    segmentKey: string,
    consentRequired: boolean,
  ) {
    if (!isSyntheticTest()) {
      init({
        segmentKey,
        consentRequired,
      });
    }
  }

  private trackFacebookEvent(
    e: IEucalyptusEvent,
    eventName: EFacebookEvent,
    eventId: string,
  ): void {
    if (isSyntheticTest()) {
      return;
    }
    window?.fbq?.(
      'track',
      eventName,
      {
        ...e,
      },
      { eventID: `api-fbp-${eventId}` },
    );
  }

  private async track(
    event: EEucalyptusEvent,
    e: IEucalyptusEvent,
    s: ISegmentEventDestination = DEFAULT_SEGMENT_EVENT_DESTINATION,
  ): Promise<void> {
    if (isSyntheticTest()) {
      return Promise.resolve();
    }
    return track(
      this.userResolver(),
      event,
      { ...e },
      {
        integrations: s,
      },
    )
      .then(() => {
        if (this.appEnv === 'development') {
          // eslint-disable-next-line no-console
          console.log(`[EVENT] ${event}`, {
            ...e,
          });
        }
      })
      .catch((err) => {
        this.logger.error(err);
      });
  }

  private createEventHandler<T extends IEucalyptusEvent>(
    event: EEucalyptusEvent,
  ): (e: T, s?: ISegmentEventDestination) => Promise<void> {
    return (e: T, s?: ISegmentEventDestination): Promise<void> => {
      return this.track(event, e, s);
    };
  }

  public identify(user: UserInfo, consent?: ContactConsent): Promise<void> {
    if (isSyntheticTest()) {
      return Promise.resolve();
    }

    const traits: Record<string, unknown> = {
      email: user.email,
    };

    if (consent === 'EMAIL') {
      traits.$consent = ['email'];
    }

    if (consent === 'NONE') {
      traits.$consent = [];
    }

    if (this.appEnv === 'development') {
      // eslint-disable-next-line no-console
      console.log(`[EVENT] identify: `, user.id, traits);
    }

    return identify(user.id, traits);
  }

  public page(
    category?: string,
    name?: string,
    properties?: Record<string, unknown>,
    options?: SegmentAnalytics.SegmentOpts,
  ): Promise<void> {
    if (isSyntheticTest()) {
      return Promise.resolve();
    }
    const resolvedProperties = {
      ...(properties ? properties : {}),
    };
    if (this.appEnv === 'development') {
      // eslint-disable-next-line no-console
      console.log(`[EVENT] page`, {
        category,
        name,
        resolvedProperties,
        options,
      });
    }
    return page(
      this.userResolver(),
      category,
      name,
      resolvedProperties,
      options,
    );
  }

  public readonly uiInteractionTracked =
    this.createEventHandler<IUIInteractionTracked>(
      EEucalyptusEvent.UI_INTERACTION_TRACKED,
    );

  public readonly account = {
    created: this.createEventHandler<IAccountCreatedEvent>(
      EEucalyptusEvent.ACCOUNT_CREATED,
    ),
  };

  public readonly flexi = {
    customerOfferingsPurchased:
      this.createEventHandler<ICustomerOfferingsPurchasedEvent>(
        EEucalyptusEvent.CUSTOMER_OFFERINGS_PURCHASED,
      ),
  };

  public readonly quiz = {
    start: this.createEventHandler<IQuizStartedEvent>(
      EEucalyptusEvent.QUIZ_STARTED,
    ),
    complete: this.createEventHandler<IQuizCompletedEvent>(
      EEucalyptusEvent.QUIZ_COMPLETED,
    ),
  };

  public readonly consultation = {
    created: this.createEventHandler<IConsultationCreatedEvent>(
      EEucalyptusEvent.CONSULTATION_CREATED,
    ),
    paid: (e: IConsultationPaidEvent): Promise<void> => {
      this.trackFacebookEvent(e, EFacebookEvent.PURCHASE, e.consultationId);

      return this.createEventHandler<IConsultationPaidEvent>(
        EEucalyptusEvent.CONSULTATION_PAID,
      )(e, DEFAULT_SEGMENT_EVENT_DESTINATION);
    },
    surveyCompleted: this.createEventHandler<IConsultationSurveyCompletedEvent>(
      EEucalyptusEvent.CONSULTATION_SURVEY_COMPLETED,
    ),
    callRecordingConsentApproved: (
      e: ICallRecordingConsentEvent,
    ): Promise<void> => {
      return this.createEventHandler<ICallRecordingConsentEvent>(
        EEucalyptusEvent.CALL_RECORDING_CONSENT_APPROVED,
      )(e, SEGMENT_DESTINATION_WITH_KLAVIYO);
    },
    paymentFailed: this.createEventHandler<IConsultationPaymentFailedEvent>(
      EEucalyptusEvent.CONSULTATION_PAYMENT_FAILED,
    ),
  };
  public readonly followUp = {
    consultationSurveyStarted:
      this.createEventHandler<IFollowUpConsultationSurveyStarted>(
        EEucalyptusEvent.FOLLOW_UP_CONSULTATION_SURVEY_STARTED,
      ),
    reminderClicked: this.createEventHandler<IFollowUpReminderClicked>(
      EEucalyptusEvent.FOLLOW_UP_REMINDER_CLICKED,
    ),
  };

  public readonly order = {
    paid: (e: IOrderPaidEvent): Promise<void> => {
      this.trackFacebookEvent(e, EFacebookEvent.ORDER_PAID, e.orderId);

      return this.createEventHandler<IOrderPaidEvent>(
        EEucalyptusEvent.ORDER_PAID,
      )(e, DEFAULT_SEGMENT_EVENT_DESTINATION);
    },
  };

  public readonly featureFlag = {
    exposed: (e: IFeatureFlagExposedEvent): Promise<void> => {
      return this.track(EEucalyptusEvent.FEATURE_EXPOSED, e, {
        ...DEFAULT_SEGMENT_EVENT_DESTINATION,
        [ESegmentDestination.KLAVIYO]: true,
        [ESegmentDestination.AMPLITUDE]: true,
      });
    },
  };

  public readonly crossSell = {
    started: (e: ICrossSellStartedEvent): Promise<void> => {
      return this.createEventHandler<ICrossSellStartedEvent>(
        EEucalyptusEvent.CROSS_SELL_STARTED,
      )(e, {
        [ESegmentDestination.ALL]: true,
        [ESegmentDestination.GOOGLE_ANALYTICS]: false,
      });
    },
  };

  public readonly otc = {
    purchase: (e: IOtcPurchaseEvent): Promise<void> =>
      this.createEventHandler<IOtcPurchaseEvent>(
        EEucalyptusEvent.OTC_PURCHASE_EVENT,
      )(e, {
        [ESegmentDestination.ALL]: false,
        [ESegmentDestination.GOOGLE_TAG_MANAGER]: true,
      }),
  };

  public readonly psat = {
    response: (e: IPsatResponseEvent): Promise<void> =>
      this.createEventHandler<IPsatResponseEvent>(
        EEucalyptusEvent.PSAT_RESPONSE,
      )(e, {
        ...DEFAULT_SEGMENT_EVENT_DESTINATION,
        [ESegmentDestination.AMPLITUDE]: true,
      }),
  };

  public readonly weightLossPatient = {
    noAlternateAddress:
      this.createEventHandler<IOutOfRangeShippingAddressCapturedEvent>(
        EEucalyptusEvent.OUT_OF_RANGE_SHIPPING_ADDRESS_CAPTURED,
      ),
  };

  public readonly education = {
    educationArticleViewed: (
      e: IEducationArticleViewedEvent,
    ): Promise<void> => {
      return this.createEventHandler<IEducationArticleViewedEvent>(
        EEucalyptusEvent.EDUCATION_ARTICLE_VIEWED,
      )(e, SEGMENT_DESTINATION_WITH_ONLY_REDSHIFT_AMPLITUDE_AND_KLAVIYO);
    },
    educationArticleRead: (e: IEducationArticleReadEvent): Promise<void> => {
      return this.createEventHandler<IEducationArticleReadEvent>(
        EEucalyptusEvent.EDUCATION_ARTICLE_READ,
      )(e, SEGMENT_DESTINATION_WITH_ONLY_REDSHIFT_AMPLITUDE_AND_KLAVIYO);
    },
  };

  public readonly medicare = {
    collectionDeferred:
      this.createEventHandler<IMedicareCollectionDeferredEvent>(
        EEucalyptusEvent.MEDICARE_COLLECTION_DEFERRED,
      ),
    collectionIneligible:
      this.createEventHandler<IMedicareCollectionIneligibleEvent>(
        EEucalyptusEvent.MEDICARE_COLLECTION_INELIGIBLE,
      ),
    formatInvalid: this.createEventHandler<IMedicareFormatEvent>(
      EEucalyptusEvent.MEDICARE_FORMAT_INVALID,
    ),
    formatValid: this.createEventHandler<IMedicareFormatEvent>(
      EEucalyptusEvent.MEDICARE_FORMAT_VALID,
    ),
  };

  public dispatchQuizUnitEvent(e: IDispatchQuizUnitEvent): Promise<void> {
    return this.track(
      EEucalyptusEvent.EXPERIMENTAL_QUIZ_RESPONSE_UNIT_SYSTEM,
      e,
      SEGMENT_DESTINATION_WITH_ONLY_REDSHIFT,
    );
  }

  public readonly checklist = {
    generated: this.createEventHandler<IChecklistGeneratedEvent>(
      EEucalyptusEvent.CHECKLIST_GENERATED,
    ),
    reminderAdded: this.createEventHandler<ICalendarReminderAddedEvent>(
      EEucalyptusEvent.CALENDAR_REMINDER_ADDED,
    ),
  };

  public readonly doctorMessageVideo = {
    played: this.createEventHandler<IDoctorVideoEvent>(
      EEucalyptusEvent.DOCTOR_MESSAGE_VIDEO_PLAYED,
    ),
    paused: this.createEventHandler<IDoctorVideoEvent>(
      EEucalyptusEvent.DOCTOR_MESSAGE_VIDEO_PAUSED,
    ),
    completed: this.createEventHandler<IDoctorVideoEvent>(
      EEucalyptusEvent.DOCTOR_MESSAGE_VIDEO_COMPLETED,
    ),
  };

  public dispatchVideoPlayedEvent(e: IVideoPlayed): Promise<void> {
    return this.track(
      EEucalyptusEvent.VIDEO_PLAYED,
      e,
      SEGMENT_DESTINATION_WITH_ONLY_REDSHIFT,
    );
  }

  public switchOfferingSelectedEvent =
    this.createEventHandler<SwitchOfferingSelected>(
      EEucalyptusEvent.SWITCH_OFFERING_SELECTED,
    );

  public pafAddonsViewedEvent = this.createEventHandler<PafAddonsViewed>(
    EEucalyptusEvent.PAF_ADDONS_VIEWED,
  );

  public pafCartItemQuantityModifiedEvent =
    this.createEventHandler<PafCartItemQuantityModified>(
      EEucalyptusEvent.PAF_CART_ITEM_QUANTITY_MODIFIED,
    );
}
