import React, { useState } from 'react';
import { LoadingSpinner, Typography } from '@eucalyptusvc/design-system';
import { formatCentsToCurrency } from '@customer-frontend/order';
import {
  ProductListTreatmentSoftwareQuery,
  ProductListTreatmentSoftwareQueryVariables,
  MutationUpdateLineItemArgs,
  MutationRemoveOtcProductFromTreatmentArgs,
  ProblemType,
} from '@customer-frontend/graphql-types';
import {
  removeOtcFromTreatmentMutation,
  updateLineItemMutation,
} from '@customer-frontend/services';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { notificationService } from '@customer-frontend/notifications';
import { getRxProductName } from 'utils/logic/order';
import { primary500 } from '@eucalyptusvc/design-system/src/theme/software/palette';

export const ProductList = ({
  otcProducts,
  treatment,
  consultation,
}: {
  otcProducts: {
    id: string;
    name: string;
    photo?: { url: string } | null;
    quantity: number;
    variants?: { price: number; id: string }[] | null;
  }[];
  treatment: {
    id: string;
    type: ProblemType;
    product: {
      name?: string | null;
      id: string;
      photo?: {
        url: string;
      } | null;
    };
    plan?: {
      amount: number;
    } | null;
  };
  consultation: {
    order?: {
      id: string;
    } | null;
  };
}): React.ReactElement => {
  const orderId = consultation.order?.id;

  const [productIdLoading, setProductIdLoading] = useState<
    string | undefined | null
  >();

  const [refreshConsultation] = useLazyQuery<
    ProductListTreatmentSoftwareQuery,
    ProductListTreatmentSoftwareQueryVariables
  >(
    gql`
      query ProductListTreatmentSoftware($id: String!) {
        treatment(id: $id) {
          id
          otcSchedules {
            id
            cadence
            isActive
          }
        }
      }
    `,
    {
      variables: {
        id: treatment.id,
      },
      onCompleted: () => {
        setProductIdLoading(null);
      },
    },
  );
  const [updateOrderLineItem] = useMutation<MutationUpdateLineItemArgs>(
    updateLineItemMutation,
  );

  const [removeOtcProductFromTreatment] =
    useMutation<MutationRemoveOtcProductFromTreatmentArgs>(
      removeOtcFromTreatmentMutation,
    );

  const handleRemoveFromOrder = async (product: {
    id: string;
    variants?: { id: string }[] | null;
  }): Promise<void> => {
    try {
      setProductIdLoading(product.id);

      if (orderId) {
        await updateOrderLineItem({
          variables: {
            orderId,
            quantity: 0,
            variantId: product?.variants?.[0]?.id,
          },
        });
      }

      await removeOtcProductFromTreatment({
        variables: {
          productId: product.id,
          id: treatment.id,
          source: 'repeat',
        },
      });
      refreshConsultation();
    } catch (error) {
      notificationService.show({
        type: 'error',
        message: "Couldn't remove product, please try again",
      });
      setProductIdLoading(null);
    }
  };

  return (
    <div>
      <Typography size="medium-paragraph">
        Your next shipment contains:
      </Typography>
      <ul className="space-y-3 mt-3">
        <ProductItem
          photoUrl={treatment.product.photo?.url}
          name={
            getRxProductName({
              product: treatment.product,
              problemType: treatment.type,
            }) ?? ''
          }
          price={treatment.plan?.amount}
        />
        {otcProducts.map((product) => (
          <ProductItem
            key={product.id}
            isOtc
            name={product.name}
            photoUrl={product.photo?.url}
            price={product.variants?.[0]?.price}
            quantity={product.quantity}
            loading={product.id === productIdLoading}
            onRemoveClick={() => {
              handleRemoveFromOrder(product);
            }}
          />
        ))}
      </ul>
    </div>
  );
};

type ProductItemProps = {
  photoUrl?: string;
  quantity?: number;
  name: string;
  price?: number;
  isOtc?: boolean;
  loading?: boolean;
  onRemoveClick?: () => void;
};

const ProductItem = ({
  name,
  price,
  isOtc,
  photoUrl,
  quantity = 1,
  loading,
  onRemoveClick,
}: ProductItemProps): React.ReactElement => {
  return (
    <li className="flex justify-between flex-start">
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          <img
            src={photoUrl}
            className="h-14 w-14 rounded-lg mr-3 border border-primary-400"
          />
          <div className="space-y-1">
            <Typography size="medium-paragraph">{name}</Typography>
            <Typography size="paragraph" color={primary500}>
              Qty: {quantity}
            </Typography>
          </div>
        </div>
        {price && (
          <Typography size="medium-paragraph">
            {formatCentsToCurrency(price)}
          </Typography>
        )}
      </div>
      <div>
        {isOtc &&
          (loading ? (
            <LoadingSpinner />
          ) : (
            <button
              className="text-gray-500 uppercase cursor-pointer"
              onClick={onRemoveClick}
            >
              remove
            </button>
          ))}
      </div>
    </li>
  );
};
