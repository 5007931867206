import {
  PhotoAngleType,
  ProgressEntryQuestionCodeType,
} from '@customer-frontend/graphql-types';
import { ContentAnswerType, QuizContentType } from './types';

export const getQuestionCodeFromAngle = (
  angle: PhotoAngleType,
): ProgressEntryQuestionCodeType => {
  switch (angle) {
    case 'LEFT':
      return 'PHOTO_LEFT';
    case 'RIGHT':
      return 'PHOTO_RIGHT';
    case 'FRONT':
      return 'PHOTO_MIDDLE';
    default:
      throw new Error('Unsupported photo angle for progress tracker');
  }
};

export const PHOTO_TYPES: ProgressEntryQuestionCodeType[] = [
  'PHOTO_LEFT',
  'PHOTO_RIGHT',
  'PHOTO_MIDDLE',
];

export const FEEDBACK_FORM_URL = 'https://pilot-aus.typeform.com/to/u8EYrJZq';

export const DEFAULT_PHOTO_OPTION = 'PHOTO_MIDDLE';
export const DEFAULT_PHOTO_ANGLE = 'FRONT';
export const DEFAULT_RESPONSE_OPTION = 'SKIN_CONFIDENCE';
export const LAST_QUESTION = 'SKIN_CONFIDENCE';

export const SKIN_CONFIDENCE_ANSWERS: ContentAnswerType[] = [
  { answer: "It's glowing!", emoji: '😄' },
  { answer: 'Good', emoji: '😃' },
  { answer: 'Hopeful', emoji: '🙂' },
  { answer: 'Okay', emoji: '😶' },
  { answer: 'Not great', emoji: '😢' },
  { answer: 'Bad', emoji: '😭' },
];

export const QUIZ_COPY: QuizContentType = {
  SKIN_CONFIDENCE: {
    question: 'How would you rate your skin today? ',
    questionType: 'RESPONSE',
    answers: SKIN_CONFIDENCE_ANSWERS,
  },
  PHOTO_LEFT: {
    questionType: 'PHOTO',
    question: 'Take or upload a photo of the left side of your face.',
  },
  PHOTO_RIGHT: {
    questionType: 'PHOTO',
    question: 'Take or upload a photo of the right side of your face.',
  },
  PHOTO_MIDDLE: {
    questionType: 'PHOTO',
    question: 'Take or upload a photo of the front of your face.',
  },
};

export const questionOrder: (keyof QuizContentType)[] = [
  'PHOTO_LEFT',
  'PHOTO_MIDDLE',
  'PHOTO_RIGHT',
  'SKIN_CONFIDENCE',
];

export const MASKED_DATE_FORMAT = 'dd/MM/yyyy';
