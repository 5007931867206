import { useForm } from 'react-hook-form';

import {
  CommsAndPrivacyUserDetailsQuery,
  CommsAndPrivacyUserDetailsQueryVariables,
  useUpdateNotificationPrefsMutation,
} from '@customer-frontend/graphql-types';
import {
  Button,
  Checkbox,
  LoadingSpinner,
  useNotification,
} from '@eucalyptusvc/design-system';
import { CommsAndPrivacyProps, CommsAndPrivacySettings } from './types';
import { useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';

export const CommsAndPrivacy = ({
  styleConfig,
}: CommsAndPrivacyProps): React.ReactElement => {
  const notify = useNotification();
  const { formatMessage } = useIntl();

  const { loading, data, refetch } = useQuery<
    CommsAndPrivacyUserDetailsQuery,
    CommsAndPrivacyUserDetailsQueryVariables
  >(
    gql`
      query CommsAndPrivacyUserDetails {
        profile {
          id
          notificationPrefs {
            id
            personalisedMarketing
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-first',
    },
  );
  const notificationPrefs = data?.profile?.notificationPrefs;

  const { register, handleSubmit, errors } = useForm<CommsAndPrivacySettings>({
    defaultValues: {
      personalisedMarketing: !!notificationPrefs?.personalisedMarketing,
    },
  });

  const [updateNotificationPrefs, { loading: updateLoading }] =
    useUpdateNotificationPrefsMutation({
      onCompleted: () => {
        notify.success({
          message: 'Communication and privacy settings updated successfully',
        });
      },
      onError: () => {
        notify.error({
          message: 'Update request has failed. Please try again.',
        });
      },
    });

  const updateSettings = async (
    data: CommsAndPrivacySettings,
  ): Promise<void> => {
    await updateNotificationPrefs({ variables: data });
    await refetch();
  };

  if (loading) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <form
        className="flex flex-col gap-8 my-2"
        onSubmit={handleSubmit(updateSettings)}
      >
        <div>
          <Checkbox
            palette={styleConfig?.optionInput?.palette}
            isChecked={!!notificationPrefs?.personalisedMarketing}
            ref={register}
            label={formatMessage({
              defaultMessage:
                'Opt in to receive personalised content and marketing communication',
              description:
                'Checkbox label for consenting to being sent personalised content from marketing',
            })}
            name="personalisedMarketing"
            errorMessage={errors?.personalisedMarketing?.message}
            disabled={loading}
          />
        </div>
        <div className="w-full md:w-1/2 self-end prima">
          <Button
            eventElementName="accountPagePersonalisedOptInSaveButton"
            isFullWidth
            palette={styleConfig?.saveButton?.palette}
            isSubmit
            isLoading={updateLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
