import React, { useState } from 'react';
import { Maybe } from '@customer-frontend/graphql-types';

interface ProgressCompareProvider {
  afterEntryId: Maybe<string>;
  beforeEntryId: Maybe<string>;
  setBeforeEntryId: (id: string | null) => void;
  setAfterEntryId: (id: string | null) => void;
}

const contextProvider: ProgressCompareProvider = {
  afterEntryId: null,
  beforeEntryId: null,
  setBeforeEntryId: () => {
    throw new Error('Progress Compare Provider not present');
  },
  setAfterEntryId: () => {
    throw new Error('Progress Tracker Provider not present');
  },
};

export const ProgressCompareContext =
  React.createContext<ProgressCompareProvider>(contextProvider);

export const useProgressCompare = (): ProgressCompareProvider =>
  React.useContext(ProgressCompareContext);

export const ProgressCompareProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [afterEntryId, setAfterEntryId] = useState<Maybe<string>>();
  const [beforeEntryId, setBeforeEntryId] = useState<Maybe<string>>();

  const value = {
    afterEntryId,
    beforeEntryId,
    setAfterEntryId,
    setBeforeEntryId,
  };

  return (
    <ProgressCompareContext.Provider value={value}>
      <div className="py-12 px-4 max-w-lg mx-auto">{children}</div>
    </ProgressCompareContext.Provider>
  );
};
