import {
  ProgressEntry,
  useProgressEntriesQuery,
} from '@customer-frontend/graphql-types';
import { useHistoryGoBackBehaviour } from '@customer-frontend/services';
import { LoadingSpinner, Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { ProfileLayout } from '../layout';
import ProgressSummary from './info-card/progress-summary';
import Welcome from './info-card/welcome';

export const ProgressTracker: React.FC = () => {
  useHistoryGoBackBehaviour();
  const { data, loading } = useProgressEntriesQuery({
    variables: {
      type: 'SKIN',
    },
  });

  const hasProgressEntries =
    data?.progressEntries && data?.progressEntries.length > 0;

  if (loading) {
    return (
      <div className="flex justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      {hasProgressEntries && (
        <div className="bg-white text-center w-full py-6">
          <Typography size="md" isBold>
            Skin Tracker
          </Typography>
        </div>
      )}
      <ProfileLayout>
        <div className="m-auto max-w-2xl">
          {hasProgressEntries ? (
            <ProgressSummary
              progressEntries={data.progressEntries as ProgressEntry[]}
            />
          ) : (
            <Welcome />
          )}
        </div>
      </ProfileLayout>
    </div>
  );
};
export default ProgressTracker;
