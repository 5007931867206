import { useForm } from 'react-hook-form';
import { gql } from '@apollo/client';

import {
  combineRules,
  maxMaskedDateInclusive,
  minMaskedDateInclusive,
  validMaskedDate,
  formatLocaleDateToISO,
} from '@customer-frontend/utils';
import {
  Button,
  TextInput,
  Typography,
  useNotification,
  useResponsive,
} from '@eucalyptusvc/design-system';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { useUpdateCustomerStatedTreatmentStatedAtMutation } from '@customer-frontend/graphql-types';
import { Card as CardType, CardKey } from '../use-profile-cards';

export const UPDATE_CUSTOMER_STATED_TREATMENT_STARTED_AT = gql`
  mutation UpdateCustomerStatedTreatmentStatedAt(
    $date: String!
    $treatmentId: String!
  ) {
    updateCustomerStatedTreatmentStatedAt(
      date: $date
      treatmentId: $treatmentId
    ) {
      id
      customerStatedTreatmentStartedAt
    }
  }
`;

export type TreatmentStartedDateCardProps = {
  firstOrderFulfilledDate: string;
  treatmentId: string;
};

const TreatmentStartedDateCard: React.FC<TreatmentStartedDateCardProps> = ({
  firstOrderFulfilledDate,
  treatmentId,
}) => {
  const { isMobile } = useResponsive();
  const notify = useNotification();
  const { register, errors, handleSubmit } = useForm<{ startedAt: string }>();
  const [updateCustomerStatedTreatmentStatedAtMutation, { loading }] =
    useUpdateCustomerStatedTreatmentStatedAtMutation();

  const submit = handleSubmit(async ({ startedAt }) => {
    await updateCustomerStatedTreatmentStatedAtMutation({
      variables: {
        date: formatLocaleDateToISO(startedAt),
        treatmentId,
      },
    });
    notify.success({ message: 'Succesfully updated treatment started date.' });
  });
  return (
    <div className="rounded-xl border border-black p-4 bg-beige-200">
      <form className="flex flex-col space-y-1" onSubmit={submit}>
        <div className="flex space-x-1">
          <CalendarIcon />
          <Typography size="medium-paragraph" isBold>
            When did you start your treatment?
          </Typography>
        </div>
        <Typography size="small-text">
          We&apos;ll use this date to personalise recommendations.
        </Typography>
        <div className="flex flex-col md:flex-row gap-3 items-center">
          <TextInput
            name={'startedAt'}
            ref={register(
              combineRules(
                validMaskedDate(),
                maxMaskedDateInclusive(new Date()),
                minMaskedDateInclusive(new Date(firstOrderFulfilledDate)),
              ),
            )}
            type="masked-date"
            errorMessage={errors?.startedAt?.message}
            placeholder="DD/MM/YYYY"
          />
          <Button isFullWidth={!!isMobile} isSubmit isLoading={loading}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export const getTreatmentStartedDateCard = ({
  props,
  key,
}: {
  props: TreatmentStartedDateCardProps;
  key: CardKey;
}): CardType<TreatmentStartedDateCardProps> => ({
  props,
  key,
  CardComponent: TreatmentStartedDateCard,
});
