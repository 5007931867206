import { gql, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect } from 'react';

import {
  AddonDrawerFocusedShopItemFragment,
  PurchaseActivationFlowAddonPriceQuery,
  PurchaseActivationFlowAddonPriceQueryVariables,
} from '@customer-frontend/graphql-types';
import {
  Button,
  Drawer,
  Dropdown,
  Markdown,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { formatCentsToCurrency } from '@customer-frontend/order';
import { usePurchaseActivationFlow } from './provider';

import { ReactComponent as Subtract } from '../../assets/subtract.svg';
import { ReactComponent as Add } from '../../assets/add-plus.svg';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import {
  UIInteractionElementType,
  UIInteractionInteraction,
  useEventService,
} from '@customer-frontend/events';

type AddonDrawerProps = {
  shopItem: AddonDrawerFocusedShopItemFragment;
  onClose: () => void;
  markdownTextColorClassName: string;
};

/**
 * Unique ID to price a single unit for this addon, we're not using the actual
 * pricing session ID as that would contain the entire order
 */
const psId = v4();

export function AddonDrawer({
  shopItem,
  onClose,
  markdownTextColorClassName,
}: AddonDrawerProps) {
  const { onAddonOfferingSelection, addonSelections, pricingSessionId } =
    usePurchaseActivationFlow();

  const otcOffering = shopItem.otcOffering;
  const sequenceSet = otcOffering?.sequenceSets?.[0];
  const defaultSequenceId = sequenceSet?.defaultSequence?.id;

  if (!otcOffering) {
    throw new Error(
      `expected shopItem with id: ${shopItem.id} to have an otcOffering`,
    );
  }

  if (!sequenceSet) {
    throw new Error(
      `expected shopItem with id: ${shopItem.id} to have at least one sequence set`,
    );
  }

  if (!defaultSequenceId) {
    throw new Error(
      `expected shopItem with id: ${shopItem.id} to have a default sequence`,
    );
  }

  const cartSequenceId = addonSelections.find(
    (as) => as.offeringId === otcOffering.id,
  )?.sequenceSelections[0].sequenceId;

  const { register, watch } = useForm({
    defaultValues: {
      // fallback to the default sequence if the user has not selected one
      selectedSequenceId: cartSequenceId ?? defaultSequenceId,
    },
  });
  const formSequenceId = watch('selectedSequenceId');

  const { success, info } = useNotification();
  const events = useEventService();
  const { formatMessage } = useIntl();

  const { data, loading } = useQuery<
    PurchaseActivationFlowAddonPriceQuery,
    PurchaseActivationFlowAddonPriceQueryVariables
  >(
    gql`
      query PurchaseActivationFlowAddonPrice(
        $pricingSessionId: ID!
        $offeringSelections: [OfferingSelectionInput!]!
      ) {
        initialPurchasePrice(
          pricingSessionId: $pricingSessionId
          offeringSelections: $offeringSelections
        ) {
          id
          amount
        }
      }
    `,
    {
      variables: {
        pricingSessionId: psId,
        offeringSelections: [
          {
            offeringId: otcOffering.id,
            sequenceSelections: [
              { sequenceSetId: sequenceSet.id, sequenceId: formSequenceId },
            ],
          },
        ],
      },
      fetchPolicy: 'cache-first',
    },
  );

  const currentQuantity = addonSelections.filter(
    (ss) => ss.offeringId == otcOffering.id,
  ).length;

  useEffect(() => {
    // user does not have this in their cart so we don't need to re-gipp their total
    if (!cartSequenceId) {
      return;
    }
    // user has changed sequence selection, we'll need to re-gipp
    if (formSequenceId !== cartSequenceId) {
      onAddonOfferingSelection(
        {
          offeringId: otcOffering.id,
          sequenceSelections: [
            { sequenceSetId: sequenceSet.id, sequenceId: formSequenceId },
          ],
        },
        currentQuantity,
      );
    }
  }, [
    formSequenceId,
    cartSequenceId,
    onAddonOfferingSelection,
    currentQuantity,
    sequenceSet.id,
    otcOffering.id,
  ]);

  return (
    <Drawer
      isOpen
      onClose={onClose}
      size="sm"
      placement="left"
      overlayStrength="medium"
    >
      <img src={otcOffering.photoUrl} className="object-cover h-96" />
      <div className="flex flex-col space-y-2 p-6">
        <Typography size="md" isBold>
          {shopItem.title}
        </Typography>
        <Typography size="medium-paragraph">
          {loading ? (
            <div className="animate-pulse">
              <div className="bg-neutral-black bg-opacity-10 rounded-sm h-6 w-12" />
            </div>
          ) : (
            formatCentsToCurrency(data?.initialPurchasePrice?.amount ?? 0, {
              includeDecimals: true,
              omitZeroDecimals: true,
            })
          )}
        </Typography>
        {(sequenceSet.sequences?.length ?? 0) > 1 && (
          <form>
            <Dropdown
              ref={register}
              label={formatMessage({ defaultMessage: 'Select' })}
              name="selectedSequenceId"
              options={
                sequenceSet.sequences
                  ?.filter((s) => s.status === 'AVAILABLE')
                  .map((s) => ({
                    value: s.id,
                    label: s.friendlyName,
                  })) ?? []
              }
            />
          </form>
        )}

        {(() => {
          if (currentQuantity === 0) {
            return (
              <Button
                eventElementName="purchaseActivationFlowAddonsAddonOverlayAddToOrder"
                onClick={() => {
                  onAddonOfferingSelection(
                    {
                      offeringId: otcOffering.id,
                      sequenceSelections: [
                        {
                          sequenceSetId: sequenceSet.id,
                          sequenceId: formSequenceId,
                        },
                      ],
                    },
                    1,
                  );
                  success({ message: 'Item added to order' });
                  events.pafCartItemQuantityModifiedEvent({
                    offeringId: otcOffering.id,
                    quantity: 1,
                    pricingSessionId,
                    quantityChangeSource: 'ADDON_PAGE',
                  });
                }}
              >
                <Typography size="paragraph" isBold>
                  <FormattedMessage defaultMessage="Add to order" />
                </Typography>
              </Button>
            );
          }

          return (
            <div className="h-12 border border-primary-400 rounded-[0.25rem] flex flex-row justify-between items-center">
              <div
                role="button"
                onClick={() => {
                  onAddonOfferingSelection(
                    {
                      offeringId: otcOffering.id,
                      sequenceSelections: [
                        {
                          sequenceSetId: sequenceSet.id,
                          sequenceId: formSequenceId,
                        },
                      ],
                    },
                    currentQuantity - 1,
                  );

                  if (currentQuantity === 1) {
                    info({ message: 'Item removed from order' });
                  }

                  events.pafCartItemQuantityModifiedEvent({
                    offeringId: otcOffering.id,
                    quantity: currentQuantity - 1,
                    pricingSessionId,
                    quantityChangeSource: 'ADDON_PAGE',
                  });

                  events.uiInteractionTracked({
                    elementName:
                      'purchaseActivationFlowAddonsAddonOverlayDecreaseQuantity',
                    elementType: UIInteractionElementType.BUTTON,
                    interaction: UIInteractionInteraction.CLICKED,
                    pageUrl: window.location.toString(),
                  });
                }}
                className="cursor-pointer h-full flex items-center w-10 justify-center"
              >
                <Subtract fill={sharedColors.neutral.black} className="w-3" />
              </div>
              <Typography size="paragraph" isBold>
                {currentQuantity}
              </Typography>
              <div
                role="button"
                onClick={() => {
                  onAddonOfferingSelection(
                    {
                      offeringId: otcOffering.id,
                      sequenceSelections: [
                        {
                          sequenceSetId: sequenceSet.id,
                          sequenceId: formSequenceId,
                        },
                      ],
                    },
                    currentQuantity + 1,
                  );

                  events.pafCartItemQuantityModifiedEvent({
                    offeringId: otcOffering.id,
                    quantity: currentQuantity + 1,
                    pricingSessionId,
                    quantityChangeSource: 'ADDON_PAGE',
                  });

                  events.uiInteractionTracked({
                    elementName:
                      'purchaseActivationFlowAddonsAddonOverlayIncreaseQuantity',
                    elementType: UIInteractionElementType.BUTTON,
                    interaction: UIInteractionInteraction.CLICKED,
                    pageUrl: window.location.toString(),
                  });
                }}
                className="cursor-pointer h-full flex items-center w-10 justify-center"
              >
                <Add fill={sharedColors.neutral.black} className="w-3" />
              </div>
            </div>
          );
        })()}
        <div className={markdownTextColorClassName}>
          <Markdown
            src={otcOffering.description}
            formatWhitespace
            linkTarget="blank"
            textSize="paragraph"
          />
        </div>
      </div>
    </Drawer>
  );
}

AddonDrawer.fragment = gql`
  fragment AddonDrawerFocusedShopItem on ShopItem {
    id
    title
    otcOffering {
      id
      photoUrl
      description
      sequenceSets {
        id
        defaultSequence {
          id
        }
        sequences {
          id
          friendlyName
          status
        }
      }
    }
  }
`;
