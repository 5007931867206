import React, { useCallback } from 'react';
import { useAuth } from '@customer-frontend/auth';
import { BillingDetails } from '@customer-frontend/order';
import { SecurityLoginCard } from './security-login';
import { ShippingDetailsCard } from './shipping-details';
import { AccountDetailsCard } from './user-details';
import {
  useBackButtonBehaviour,
  useProfile,
} from '@customer-frontend/services';
import { useHistory } from 'react-router-dom';
import { primaryNavigationRoutes, routes } from 'utils/routes';
import {
  FeatureFlagBoolean,
  FeatureFlagMultivariate,
  useFeatureFlagBoolean,
} from '@customer-frontend/feature-flags';
import { Card, Typography, Button } from '@eucalyptusvc/design-system';
import {
  CommsAndPrivacy,
  EditMedicareDetails,
} from '@customer-frontend/profile';

export const Account = (): React.ReactElement | null => {
  const { logout } = useAuth();
  const history = useHistory();
  useBackButtonBehaviour(() => history.push(primaryNavigationRoutes.home));
  const profileResp = useProfile();

  const user = profileResp?.data?.profile;

  const marketingConsentUpdatedUI = useFeatureFlagBoolean(
    'FF_MARKETING_CONSENT_UI_UPDATES',
  );

  const onUpdated = useCallback(() => {
    profileResp.refetch();
  }, [profileResp]);

  const handleLogout = useCallback(() => {
    logout();
    history.push(routes.login);
  }, [logout, history]);

  if (!user) {
    return null;
  }

  return (
    <section className="bg-beige-100">
      <div className="bg-white text-center w-full py-6">
        <Typography size="md" isBold>
          <span className="capitalize">Account</span>
        </Typography>
      </div>
      <div className="max-w-2xl px-4 py-8 mb-8 mx-auto overflow-hidden sm:overflow-visible">
        <div className="space-y-4">
          <AccountDetailsCard user={user} onUpdated={onUpdated} />
          <SecurityLoginCard />
          <ShippingDetailsCard address={user.address} onUpdated={onUpdated} />
          <Card>
            <div className="space-y-4">
              <Typography size="md" isBold>
                Billing details
              </Typography>
              <BillingDetails
                hasZip={!!user.zip}
                savedPaymentMethods={user.savedPaymentMethods}
                defaultPaymentGateway={user.defaultPaymentGateway}
              />
            </div>
          </Card>
          {!marketingConsentUpdatedUI && (
            <FeatureFlagBoolean flagKey="PERSONALISED_MARKETING_CONSENT">
              <Card>
                <div className="mb-2">
                  <Typography isBold size="md">
                    Communications & privacy
                  </Typography>
                </div>
                <CommsAndPrivacy />
              </Card>
            </FeatureFlagBoolean>
          )}
          <FeatureFlagMultivariate
            flagKey={'ENABLE_MEDICARE_COLLECTION'}
            truthyFlagValues={['variation']}
          >
            <EditMedicareDetails />
          </FeatureFlagMultivariate>
          <Button
            level="secondary"
            eventElementName="logoutButton"
            onClick={handleLogout}
          >
            Log out
          </Button>
        </div>
      </div>
    </section>
  );
};
