import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Typography } from '@eucalyptusvc/design-system';
import { StatusLayout } from './status-card/components';
import BottleV2 from 'assets/bottle-v2.png';
import HairTreatmentBox from 'assets/hair-treatment-box.png';
import clsx from 'clsx';
import { ProblemType } from '@customer-frontend/graphql-types';
import { Card as CardType, CardKey } from './use-profile-cards';
import { routes } from 'utils/routes';

type SupportedProblemTypes = Extract<ProblemType, 'HAIR' | 'SKIN_GENERAL'>;

const CONTENT: Record<
  SupportedProblemTypes,
  { subheading: string; paragraph: string }
> = {
  SKIN_GENERAL: {
    subheading: 'Get your personalised skin treatment',
    paragraph: `We need to learn about your skin first.

      Complete the consult questions, then we’ll connect you with a practitioner who can prescribe a custom treatment plan.`,
  },
  HAIR: {
    subheading: 'Get a personalised prescription',
    paragraph:
      "Take our quiz, then we'll connect you with a practitioner who can prescribe you a treatment to address your hair concerns.",
  },
};

export type StartQuizCardProps = {
  problemType: SupportedProblemTypes;
  showImage?: boolean;
};

export const StartQuizCard: React.FC<StartQuizCardProps> = ({
  problemType,
  showImage = true,
}) => {
  const history = useHistory();

  return (
    <div>
      {showImage && (
        <div className="relative">
          <img
            className={clsx('absolute right-0 z-10', {
              '-top-10 -right-5 h-36 md:top-4 md:-right-18 md:h-60':
                problemType === 'SKIN_GENERAL',
              '-right-8 h-20 md:-right-10 md:h-28': problemType === 'HAIR',
            })}
            src={problemType === 'SKIN_GENERAL' ? BottleV2 : HairTreatmentBox}
            alt="treatment"
          />
        </div>
      )}
      <Card size="sm">
        <StatusLayout
          title=""
          problemType={problemType}
          testId="start-consult-card"
        >
          <Typography size="medium-paragraph" isBold>
            {CONTENT[problemType].subheading}
          </Typography>
          <div className={clsx(problemType === 'SKIN_GENERAL' && 'md:pr-16')}>
            <Typography size="medium-paragraph">
              {CONTENT[problemType].paragraph}
            </Typography>
          </div>
          <Button
            eventElementName="treatmentPageStartQuizButton"
            onClick={() => {
              history.push(`${routes.startWelcome}?problemType=${problemType}`);
            }}
          >
            Take the quiz
          </Button>
        </StatusLayout>
      </Card>
    </div>
  );
};

export const getStartQuizCard = ({
  props,
  key,
}: {
  props: StartQuizCardProps;
  key: CardKey;
}): CardType<StartQuizCardProps> => ({
  props,
  key,
  CardComponent: StartQuizCard,
});
