import {
  SkinTrackerProfileCard,
  SkinTrackerProfileCardProps,
  Variant,
} from 'components/skin-tracker-profile-card';
import { Card as CardType, CardKey } from './use-profile-cards';

export { Variant };

export const getSkinTrackerProfileCard = ({
  props,
  key,
}: {
  props: SkinTrackerProfileCardProps;
  key: CardKey;
}): CardType<SkinTrackerProfileCardProps> => ({
  props,
  key,
  CardComponent: SkinTrackerProfileCard,
});
