// TreatmentStatus
import { AwaitingAnswersCardContent } from '../awaiting-answers';

// Consultation Status
import { AwaitingPaymentCardContent } from '../awaiting-payment';
import { AwaitingSurveyCardContent } from '../awaiting-survey';
import { AwaitingDoctorCardContent } from '../awaiting-doctor';
import { DoctorAssignedCardContent } from '../doctor-assigned';
import { DoctorCompletedCardContent } from '../doctor-rejected';
import { Reminder } from '../reminder';

import { activeReminder } from '@customer-frontend/consultation';
import { StatusConfigReturnType } from '../types';
import {
  clinicianNoun,
  getInlineReadableProblemType,
} from '@customer-frontend/utils';
import {
  ClinicianType,
  ConsultationStage,
  ConsultationStatus,
  Maybe,
  ProblemType,
} from '@customer-frontend/graphql-types';

export const getConsultationStatusContent = (consultation: {
  id: string;
  status: ConsultationStatus;
  type: ProblemType;
  stage: ConsultationStage;
  doctor?: {
    fullName?: Maybe<string>;
    fullClinicianName: string;
    shortClinicianName: string;
    provider?: {
      clinicianType: ClinicianType;
    } | null;
  } | null;
  reminders?:
    | {
        id: string;
        canceled: boolean;
        doneAt?: string;
        scheduledAt: string;
      }[]
    | null;
  allowPatientToSendMessageToDoctor: boolean;
}): Omit<StatusConfigReturnType, 'problemType'> | null => {
  const clinician = consultation.doctor?.provider?.clinicianType
    ? clinicianNoun(consultation.doctor?.provider?.clinicianType)
    : 'practitioner';

  if (consultation.reminders) {
    const reminder = activeReminder(consultation);

    if (reminder !== null) {
      return {
        title: '',
        component: Reminder,
      };
    }
  }

  switch (consultation.status) {
    case 'AWAITING_SURVEY':
      return {
        title: 'Just a few questions to go',
        component: AwaitingSurveyCardContent,
      };
    case 'AWAITING_PAYMENT':
      return {
        title: "You've finished the quiz!",
        component: AwaitingPaymentCardContent,
      };
    case 'AWAITING_DOCTOR':
      return {
        title: 'We are finding you a practitioner',
        component: AwaitingDoctorCardContent,
      };
    case 'DOCTOR_ASSIGNED':
      return {
        title: `Your ${clinician} is reviewing your ${getInlineReadableProblemType(
          consultation.type,
        )} concerns`,
        component: DoctorAssignedCardContent,
      };
    case 'AWAITING_ANSWERS':
      return {
        title: `Your ${clinician} has some questions for you`,
        component: AwaitingAnswersCardContent,
      };
    case 'AWAITING_SHIPPING_DETAILS':
    case 'DOCTOR_COMPLETED':
      return {
        title: null,
        component: DoctorCompletedCardContent,
      };
    default:
      return null;
  }
};

export default getConsultationStatusContent;
