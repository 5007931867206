import { type ReactElement } from 'react';
import { Typography, Button } from '@eucalyptusvc/design-system';
import { QuizCompletedCardContentQuizApplicationFragment } from '@customer-frontend/graphql-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { gql } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';

interface QuizCompletedCardContentProps {
  quizApplication: QuizCompletedCardContentQuizApplicationFragment;
  routes: {
    quiz: {
      rejected: (id: string) => string;
    };
  };
}

export function QuizCompletedCardContent({
  quizApplication,
  routes,
}: QuizCompletedCardContentProps): ReactElement {
  const config = getConfig();
  const history = useHistory();
  const hasNotEligibleQuizOutcome = quizApplication.outcomes?.some(
    (o) => o.__typename === 'NotEligibleQuizOutcome',
  );

  return (
    <div className="space-y-4">
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="Unfortunately, we've reviewed your information and determined {brandTitle} does not have a suitable treatment for you today."
          values={{
            brandTitle: config.title,
          }}
        />
      </Typography>

      {hasNotEligibleQuizOutcome && (
        <Button
          level="primary"
          isFullWidth
          onClick={(): void => {
            history.push(routes.quiz.rejected(quizApplication.id));
          }}
        >
          <FormattedMessage defaultMessage="View details" />
        </Button>
      )}
    </div>
  );
}

QuizCompletedCardContent.fragment = gql`
  fragment QuizCompletedCardContentQuizApplication on QuizApplication {
    id
    outcomes {
      ... on QuizOutcomeV2 {
        id
      }
    }
  }
`;
