import React from 'react';
import { useHistory } from 'react-router-dom';
import { OTCPurchaseDrawer } from '@customer-frontend/order';
import { useProfile } from '@customer-frontend/services';
import { ReactComponent as SoftwareLogo } from 'assets/software-logo.svg';
import { primaryNavigationRoutes } from 'utils/routes';
import { ReactComponent as OTCImage } from 'assets/software-otc.svg';
import { ReactComponent as PurchaseCompleteImage } from 'assets/hands.svg';
import { isSkinProblemType } from '@customer-frontend/utils';

export const CartDrawer = (): React.ReactElement | null => {
  const history = useHistory();

  const profileResp = useProfile();
  const user = profileResp.data?.profile;
  const consultations = user?.consultations ?? [];
  const treatmentId = consultations.find(({ type }) => isSkinProblemType(type))
    ?.treatment?.id;

  if (!treatmentId) {
    return null;
  }

  return (
    <OTCPurchaseDrawer
      brandLogo={<SoftwareLogo className="w-24 mx-auto h-auto" />}
      emptyCartConfig={{
        subHeading: 'Better skin begins with the right routine.',
        ctaText: 'Explore our products',
        onCtaClick: () => history.push(primaryNavigationRoutes.discover),
        image: <OTCImage />,
      }}
      purchaseCompletedImage={<PurchaseCompleteImage />}
      enableCart
    />
  );
};
