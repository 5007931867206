import { useMutation } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';
import {
  MutationRequestReviewTreatmentSupportArgs,
  ProblemType,
  Treatment,
} from '@customer-frontend/graphql-types';
import { notificationService } from '@customer-frontend/notifications';
import { useOTCCart } from '@customer-frontend/order';
import {
  requestReviewTreatmentSupportDocument,
  useRequestReviewTreatment,
} from '@customer-frontend/services';
import {
  Button,
  Modal,
  Typography,
  useNotification,
  ButtonPalette,
} from '@eucalyptusvc/design-system';
import { uiStorages } from '@customer-frontend/ui-storage';
import ReviewBottle from 'assets/review_bottle.png';
import ReviewBowl from 'assets/review_bowl.png';
import ReviewFace from 'assets/review_face.png';
import { SupportMedical } from 'pages/treatment/support-flow/support-medical';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { logger } from 'utils/logging';
import { routes } from 'utils/routes';
import { CheckInQuizOutcomes } from '../types';

type ActionRowProps = {
  imageSrc: string;
  text: string;
  buttonText: string;
  handleButtonPress?: () => void;
  palette: ButtonPalette;
  buttonDisabled?: boolean;
};
const ActionRow: React.FC<ActionRowProps> = ({
  imageSrc,
  text,
  buttonText,
  handleButtonPress,
  palette,
  buttonDisabled = false,
}: ActionRowProps) => {
  return (
    <div className="flex">
      <div className="flex flew-row items-center w-3/4">
        <img src={imageSrc} alt="bottle" className="w-11" />
        <div className="mx-4">
          <Typography size="small-text">{text}</Typography>
        </div>
      </div>

      {handleButtonPress && (
        <div className="flex flex-col justify-center ml-auto w-24">
          <Button
            size="sm"
            onClick={handleButtonPress}
            palette={palette}
            isDisabled={buttonDisabled}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

const markSupportRequested = (outcomeId: string): void => {
  uiStorages.local.setValue(`supportRequested_${outcomeId}`, 'true');
};

const hasSupportBeenRequested = (outcomeId: string): boolean => {
  return Boolean(uiStorages.local.getValue(`supportRequested_${outcomeId}`));
};

export const CheckInQuizOutcomeActions = ({
  outcomes,
  treatmentId,
  type,
  isOffTrack,
  quizApplicationId,
}: {
  outcomes: CheckInQuizOutcomes;
  treatmentId: string;
  type: ProblemType;
  isOffTrack: boolean;
  quizApplicationId?: string;
}): React.ReactElement => {
  const history = useHistory();
  const notify = useNotification();
  const { buyNow } = useOTCCart();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { supportEmail } = getConfig();

  const [requestReviewTreatmentSupport, { loading: requestLoading }] =
    useMutation<
      { requestReviewTreatmentSupport: Treatment },
      MutationRequestReviewTreatmentSupportArgs
    >(requestReviewTreatmentSupportDocument);

  const [requestReviewTreatment] = useRequestReviewTreatment({
    onCompleted({ requestReviewTreatment }) {
      const consultationId = requestReviewTreatment?.id;
      if (!consultationId) {
        logger.error(
          `Consultation id ${consultationId} not found, unable to redirect to review consultation for treatment ${treatmentId}`,
        );
        notify.error({
          message: `Something went wrong, please email ${supportEmail}`,
        });
      } else if (requestReviewTreatment.status === 'AWAITING_SURVEY') {
        history.push(routes.consultation.review(consultationId));
      } else {
        history.push(routes.profile);
      }
    },
    onError() {
      notify.error({
        message: `Unable to request review, please email ${supportEmail}`,
      });
    },
  });

  const handleTriggerReview = (): void => {
    requestReviewTreatment({
      variables: {
        input: { treatmentId, trigger: 'user', reason: 'MEDICATION_CHANGES' },
      },
    });
  };

  const handleBuyItNowClick = (product: {
    variants: {
      public: boolean;
      id: string;
    }[];
  }): void => {
    const variant = product.variants.find((v) => v.public);
    if (!variant?.id) {
      logger.error(
        `Variant id ${variant?.id} not found, unable to add to cart`,
      );
      notify.error({
        message: `Failed to purchase, please email ${supportEmail}`,
      });
      return;
    }
    buyNow(variant.id);
  };

  const handleFormSubmit = useCallback(
    async (formData: { subject: string; body: string }): Promise<void> => {
      const { subject, body } = formData;

      const supportOutcome = outcomes?.find(
        (o) => o.__typename === 'ZendeskQuizOutcome',
      );

      try {
        setSubmitLoading(true);
        await requestReviewTreatmentSupport({
          variables: {
            quizApplicationId,
            treatmentId: treatmentId,
            subject: `Check In Quiz: ${subject}`,
            body,
            category: 'checkInQuiz',
          },
        });
        markSupportRequested(supportOutcome?.id ?? '');
      } catch {
        notificationService.show({
          message: `Unable to send message, please email ${supportEmail}`,
          type: 'error',
        });
      } finally {
        setSubmitLoading(false);
        setIsOpen(false);
      }
    },
    [
      outcomes,
      requestReviewTreatmentSupport,
      quizApplicationId,
      treatmentId,
      supportEmail,
    ],
  );

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleOpenSupportTicket = (): void => {
    setIsOpen(true);
  };

  const outcomeElements = outcomes?.map((outcome) => {
    switch (outcome.__typename) {
      case 'ReviewQuizOutcome':
        return (
          <ActionRow
            buttonText="Review"
            imageSrc={ReviewBottle}
            key={outcome.__typename}
            text=" Have a review with a practitioner to discuss a stronger formula"
            palette={isOffTrack ? 'white' : 'default'}
            handleButtonPress={handleTriggerReview}
          />
        );
      case 'BuyOtcQuizOutcome': {
        return outcome.products?.map((product) => {
          return (
            <ActionRow
              buttonText="Add to cart"
              imageSrc={product.photo?.url || ReviewBottle}
              key={`${outcome.__typename}_${product.id}`}
              text={`Try our ${product.name}`}
              palette={isOffTrack ? 'white' : 'default'}
              handleButtonPress={() => handleBuyItNowClick(product)}
            />
          );
        });
      }
      case 'ZendeskQuizOutcome': {
        return (
          <ActionRow
            buttonText="Review"
            imageSrc={ReviewBowl}
            key={outcome.__typename}
            text="Review your treatment with our medical team"
            palette={isOffTrack ? 'white' : 'default'}
            handleButtonPress={handleOpenSupportTicket}
            buttonDisabled={hasSupportBeenRequested(outcome.id)}
          />
        );
      }
      case 'IncreaseUsageQuizOutcome': {
        return (
          <ActionRow
            buttonText="Review"
            imageSrc={ReviewFace}
            key={outcome.__typename}
            palette={isOffTrack ? 'white' : 'default'}
            text="Try using your treatment more often. We recommend 5 times a week."
          />
        );
      }
      default:
        break;
    }
  });

  return (
    <>
      <Typography size="small-text" isBold>
        Your next steps
      </Typography>
      {outcomeElements}
      <Modal
        title={
          'Our medical support team is here to provide guidance on your treatment'
        }
        isOpen={isOpen}
        onClose={handleClose}
      >
        <SupportMedical
          category={'checkInQuiz'}
          onClose={handleClose}
          onSubmit={handleFormSubmit}
          submitLoading={submitLoading}
          requestLoading={requestLoading}
          problemType={type}
        />
      </Modal>
    </>
  );
};
