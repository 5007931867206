import React from 'react';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { Card as CardType, CardKey } from './use-profile-cards';
import { useEducationCollectionQuery } from '@customer-frontend/graphql-types';
import { useHistory } from 'react-router-dom';
import { learnRoutes } from 'utils/routes';
import { ReactComponent as BookOpenThin } from 'assets/icons/book-open-thin.svg';
import EducationItem from 'components/learn/EducationItem';

const EDUCATION_COLLECTION_KEY = 'e26df22f-61ed-4b4a-b827-25311ccde481';

const EducationCard = (): React.ReactElement | null => {
  const history = useHistory();

  const { data, loading } = useEducationCollectionQuery({
    variables: {
      id: EDUCATION_COLLECTION_KEY,
    },
  });

  const handleBrowseClick = (): void => {
    history.push(learnRoutes.root);
  };

  const handleArticleSelect = (id: string): void => {
    history.push(learnRoutes.article(id));
  };

  if (loading) {
    return null;
  }

  return (
    <div className="bg-beige-200 rounded-xl">
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center space-x-2 mt-1">
          <BookOpenThin className="h-6 w-6" />
          <Typography size="sm" isBold>
            {data?.educationCollection?.name}
          </Typography>
        </div>

        <Button onClick={handleBrowseClick} level="secondary" size="sm">
          Browse all
        </Button>
      </div>
      <div className="flex space-x-2 pb-8 px-4 overflow-x-auto">
        {data?.educationCollection?.readings?.map((reading) => {
          return (
            <div
              key={reading.id}
              className="w-40 flex:none md:flex-1 flex flex-shrink-0"
            >
              <EducationItem
                onSelect={() => handleArticleSelect(reading.id)}
                {...reading}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const getEducationCard = ({
  key,
}: {
  key: CardKey;
}): CardType<Record<string, never>> => ({
  CardComponent: EducationCard,
  key,
  props: {},
});
