/* eslint-disable react/jsx-key */
import React from 'react';

import { BeforeAndAfter, Ingredient, IngredientDescription } from './types';
import Niacinamide from 'assets/icons/niacinamide.svg';
import Hyaluronic from 'assets/icons/hyaluronic.svg';
import Retinoid from 'assets/icons/retinoid.svg';
import Azelaic from 'assets/icons/azelaic.svg';
import AndreaBefore from 'assets/prepayment-loading/AndreaBefore.png';
import AndreaAfter from 'assets/prepayment-loading/AndreaAfter.png';
import AvneetAfter from 'assets/prepayment-loading/AvneetAfter.png';
import AvneetBefore from 'assets/prepayment-loading/AvneetBefore.png';

import ChiraagAfter from 'assets/prepayment-loading/ChiraagAfter.png';
import ChiraagBefore from 'assets/prepayment-loading/ChiraagBefore.png';

import ConnieAfter from 'assets/prepayment-loading/ConnieAfter.png';
import ConnieBefore from 'assets/prepayment-loading/ConnieBefore.png';

import EbonyAfter from 'assets/prepayment-loading/EbonyAfter.png';
import EbonyBefore from 'assets/prepayment-loading/EbonyBefore.png';

import JackAfter from 'assets/prepayment-loading/JackAfter.png';
import JackBefore from 'assets/prepayment-loading/JackBefore.png';

import JessieAfter from 'assets/prepayment-loading/JessieAfter.png';
import JessieBefore from 'assets/prepayment-loading/JessieBefore.png';

import KazelAfter from 'assets/prepayment-loading/KazelAfter.png';
import KazelBefore from 'assets/prepayment-loading/KazelBefore.png';

import KwanAfter from 'assets/prepayment-loading/KwanAfter.png';
import KwanBefore from 'assets/prepayment-loading/KwanBefore.png';

import LaurenAfter from 'assets/prepayment-loading/LaurenAfter.png';
import LaurenBefore from 'assets/prepayment-loading/LaurenBefore.png';

import LinhAfter from 'assets/prepayment-loading/LinhAfter.png';
import LinhBefore from 'assets/prepayment-loading/LinhBefore.png';

import PeggyAfter from 'assets/prepayment-loading/PeggyAfter.png';
import PeggyBefore from 'assets/prepayment-loading/PeggyBefore.png';

import TaniAfter from 'assets/prepayment-loading/TaniAfter.png';
import TaniBefore from 'assets/prepayment-loading/TaniBefore.png';

import BelindaAfter from 'assets/prepayment-loading/BelindaAfter.png';
import BelindaBefore from 'assets/prepayment-loading/BelindaBefore.png';

import SaraAfter from 'assets/prepayment-loading/SaraAfter.png';
import SaraBefore from 'assets/prepayment-loading/SaraBefore.png';

import JodieAfter from 'assets/prepayment-loading/JodieAfter.png';
import JodieBefore from 'assets/prepayment-loading/JodieBefore.png';

import AzelaicAcidInfoThumbnail from 'assets/ingredients/azelaic-acid.jpg';
import RetinoidInfoThumbnail from 'assets/ingredients/retinoid.jpg';
import NiacinamideInfoThumbnail from 'assets/ingredients/niacinamide.jpg';
import HyaluronicAcidInfoThumbnail from 'assets/ingredients/hyaluronic-acid.jpg';

const ingredientModalUtm =
  '?utm_source=consultation_profile&utm_medium=ingredient_information_modal';

export const niacinamide: Ingredient = {
  name: 'Niacinamide',
  description: 'for brighter and more glowing skin',
  icon: Niacinamide,
};
export const niacinamideExtended: IngredientDescription = {
  thumbnail: NiacinamideInfoThumbnail,
  name: niacinamide.name,
  tagline: 'To increase skin brightness and elasticity',
  summary:
    'Niaciniamide is a bit of a wonder ingredient that can do almost anything. It’s often used to combat the signs of ageing, as it can increase skin brightness and elasticity while reducing fine lines and wrinkles. It can also been used to fight everything from acne to eczema—and it’s even been suggested that it might prevent some skin cancers.',
  keyBenefits: [
    {
      key: 'free-radicals',
      component: [
        <strong>Prevents (and repairs) damage done by free radicals.</strong>,
        <span>
          {' '}
          This damage can speed up the ageing process of our skin cells –
          niacinamide is an antioxidant that prevents this from happening.
        </span>,
      ],
    },
    {
      key: 'protective-layer',
      component: [
        <strong>Boosts the protective layer of your skin</strong>,
        <span>
          {' '}
          by increasing production of ceramides and lipids, ultimately helping
          you retain moisture and keeps your skin hydrated.
        </span>,
      ],
    },
    {
      key: 'collagen-production',
      component: [
        <strong>Increases collagen production</strong>,
        <span>
          , which improves skin elasticity and reduces fine lines and wrinkles.
        </span>,
      ],
    },
    {
      key: 'reduce-oil',
      component: [
        <strong>Reduces oil production</strong>,
        <span>
          {' '}
          by regulating the sebaceous glands. This prevents excessive oil
          production and can reduce acne and breakouts.
        </span>,
      ],
    },
  ],
  link:
    'https://www.skin.software/ingredients/niacinamide' + ingredientModalUtm,
};
export const hyaluronicAcid: Ingredient = {
  name: 'Hyaluronic Acid',
  description: 'the ultimate hydrating agent.',
  icon: Hyaluronic,
};
export const hyaluronicAcidExtended: IngredientDescription = {
  thumbnail: HyaluronicAcidInfoThumbnail,
  name: hyaluronicAcid.name,
  tagline: 'To increase skin hydration',
  summary:
    "Hyaluronic Acid is the ultimate hydrating ingredient. It's a humectant, which means it holds or retains water. It offers intense, second-to-none hydration and leaves your skin looking plumper, firmer, and dewier. Hyaluronic acid serves to soothe irritation and reduce dryness that may be caused by stronger actives like retinoids, making it an excellent addition to custom treatments.",
  keyBenefits: [
    {
      key: 'hydration',
      component: [
        <span>
          Intense hydration reduces dryness and leaves your skin feeling fresh
          and dewy.
        </span>,
      ],
    },
    {
      key: 'moisture-retention',
      component: [
        <span>
          Retains moisture in your skin, which keeps your skin plump and
          smoothes fine lines.
        </span>,
      ],
    },
    {
      key: 'elasticity',
      component: [
        <span>
          Improves skin elasticity and firmness which serves to prevent dullness
          and sagging skin.
        </span>,
      ],
    },
  ],
  link:
    'https://www.skin.software/ingredients/hyaluronic-acid' +
    ingredientModalUtm,
};
export const azelaicAcid: Ingredient = {
  name: 'Azelaic Acid',
  description: 'reduces redness and fades pigmentation',
  icon: Azelaic,
};
export const azelaicAcidExtended: IngredientDescription = {
  thumbnail: AzelaicAcidInfoThumbnail,
  name: azelaicAcid.name,
  tagline: 'To reduce acne and prevent clogged pores',
  summary:
    'Azelaic Acid is a naturally occurring acid that is praised for its ability to fight acne and fade pigmentation. Its antimicrobial properties make it extremely effective in clearing active acne, reducing redness and scarring, and preventing future breakouts.',
  keyBenefits: [
    {
      key: 'bacteria',
      component: [
        <strong>Kills acne-causing bacteria</strong>,
        <span>
          {' '}
          and inhibits their reproduction after only one application to the
          skin. With continued use, azelaic acid keeps bacteria at bay and helps
          prevent acne from recurring.
        </span>,
      ],
    },
    {
      key: 'clogged-pores',
      component: [
        <strong>Prevents clogged pores</strong>,
        <span>
          {' '}
          by gently exfoliating the skin. This removes dead skin cells that
          cause dull skin, while also preventing the cells from clumping
          together and blocking the follicle, leading to pimples.
        </span>,
      ],
    },
    {
      key: 'inflammation',
      component: [
        <strong>Reduces inflammation</strong>,
        <span>
          {' '}
          caused by acne and rosacea. Azelaic Acid&apos;s antioxidant properties
          soothes irritation and heals the skin, particularly improving acne
          scars and trauma caused by breakouts.
        </span>,
      ],
    },
    {
      key: 'gentle-skin',
      component: [
        <strong>Gentle on the skin</strong>,
        <span>
          {' '}
          and works well with stronger actives, making it one of the friendliest
          actives available. Azelaic acid is ideal for anyone with sensitive
          skin and is safe for pregnant people.
        </span>,
      ],
    },
  ],
  link: 'https://www.skin.software/ingredients/aza' + ingredientModalUtm,
};
export const retinoid: Ingredient = {
  name: 'Retinoid',
  description: 'increases collagen production and unblocks pores',
  icon: Retinoid,
};
export const retinoidExtended: IngredientDescription = {
  thumbnail: RetinoidInfoThumbnail,
  name: retinoid.name,
  tagline: 'To reduce acne and fine lines',
  summary:
    "Retinoid goes by a number of names including Tretinoin, Retinoic Acid, Retin A and Vitamin A. It is a highly effective medication that increases the rate of skin cell turnover. In Australia, it's only available via prescription so that practitioners can counsel patients on the best way to use and apply it.",
  keyBenefits: [
    {
      key: 'growth',
      component: [
        <span>It </span>,
        <strong>stimulates the growth of new skin cells</strong>,
        <span>
          {' '}
          and increases the rate that old skin cells are shed. Tretinoin works
          to reduce the production of sebum (the oil on your skin) as well as
          reduce keratinization (the production of excess skin cells) which both
          lead to the formation of comedones (blackheads).
        </span>,
      ],
    },
    {
      key: 'collagen-production',
      component: [
        <span>It promotes </span>,
        <strong>collagen production</strong>,
        <span>
          . As our body&apos;s collagen production reduces with age it causes
          our skin to sag - a key sign of ageing. Increased collagen helps to
          plump the skin and increases epidermal thickness.
        </span>,
      ],
    },
    {
      key: 'anti-inflammatory',
      component: [
        <span>It has </span>,
        <strong>anti-inflammatory</strong>,
        <span>
          {' '}
          properties. For inflamed acne (papules and pustules) Tretinoin helps
          by reducing redness and swelling.
        </span>,
      ],
    },
  ],
  link: 'https://www.skin.software/ingredients/vitamin-a' + ingredientModalUtm,
};

export const beforeAndAfter: BeforeAndAfter[] = [
  {
    title: 'Belinda, 52, Sydney',
    beforeImageUrl: BelindaBefore,
    afterImageUrl: BelindaAfter,
    goals: [
      {
        name: 'fine lines',
        color: 'bg-sticker-green',
      },
    ],
  },
  {
    title: 'Sara, 48, Sydney',
    beforeImageUrl: SaraBefore,
    afterImageUrl: SaraAfter,
    goals: [
      {
        name: 'fine lines',
        color: 'bg-sticker-green',
      },
    ],
  },
  {
    title: 'Jodie, 55, Sydney',
    beforeImageUrl: JodieBefore,
    afterImageUrl: JodieAfter,
    goals: [
      {
        name: 'fine lines',
        color: 'bg-sticker-green',
      },
    ],
  },
  {
    title: 'Andrea, 27, Sydney',
    beforeImageUrl: AndreaBefore,
    afterImageUrl: AndreaAfter,
    goals: [
      {
        name: 'pimples',
        color: 'bg-sticker-red',
      },
      {
        name: 'redness',
        color: 'bg-sticker-purple',
      },
    ],
  },
  {
    title: 'Linh, 22, Sydney',
    beforeImageUrl: LinhBefore,
    afterImageUrl: LinhAfter,
    goals: [
      {
        name: 'pimples',
        color: 'bg-sticker-red',
      },
    ],
  },
  {
    title: 'Jack, 22, Gold Coast',
    beforeImageUrl: JackBefore,
    afterImageUrl: JackAfter,
    goals: [
      {
        name: 'pimples',
        color: 'bg-sticker-red',
      },
    ],
  },
  {
    title: 'Kazel, 26, Queensland',
    beforeImageUrl: KazelBefore,
    afterImageUrl: KazelAfter,
    goals: [
      {
        name: 'pimples',
        color: 'bg-sticker-red',
      },
    ],
  },
  {
    title: 'Lauren',
    beforeImageUrl: LaurenBefore,
    afterImageUrl: LaurenAfter,
    goals: [
      {
        name: 'pimples',
        color: 'bg-sticker-red',
      },
      {
        name: 'redness',
        color: 'bg-sticker-purple',
      },
    ],
  },
  {
    title: 'Connie, 23, Sydney',
    beforeImageUrl: ConnieBefore,
    afterImageUrl: ConnieAfter,
    goals: [
      {
        name: 'fine lines',
        color: 'bg-sticker-green',
      },
    ],
  },
  {
    title: 'Kwan, 25',
    beforeImageUrl: KwanBefore,
    afterImageUrl: KwanAfter,
    goals: [
      {
        name: 'fine lines',
        color: 'bg-sticker-green',
      },
    ],
  },
  {
    title: 'Peggy',
    beforeImageUrl: PeggyBefore,
    afterImageUrl: PeggyAfter,
    goals: [
      {
        name: 'fine lines',
        color: 'bg-sticker-green',
      },
      {
        name: 'dullness',
        color: 'bg-sticker-blue',
      },
    ],
  },
  {
    title: 'Tani, 27, Queensland',
    beforeImageUrl: TaniBefore,
    afterImageUrl: TaniAfter,
    goals: [
      {
        name: 'pigmentation',
        color: 'bg-sticker-yellow',
      },
      {
        name: 'dullness',
        color: 'bg-sticker-blue',
      },
      {
        name: 'redness',
        color: 'bg-sticker-purple',
      },
    ],
  },
  {
    title: 'Avneet, 22, Sydney',
    beforeImageUrl: AvneetBefore,
    afterImageUrl: AvneetAfter,
    goals: [
      {
        name: 'pigmentation',
        color: 'bg-sticker-yellow',
      },
    ],
  },
  {
    title: 'Ebony, 24, Melbourne',
    beforeImageUrl: EbonyBefore,
    afterImageUrl: EbonyAfter,
    goals: [
      {
        name: 'pigmentation',
        color: 'bg-sticker-yellow',
      },
      {
        name: 'redness',
        color: 'bg-sticker-purple',
      },
    ],
  },
  {
    title: 'Chiraag, 22, Melbourne',
    beforeImageUrl: ChiraagBefore,
    afterImageUrl: ChiraagAfter,
    goals: [
      {
        name: 'pigmentation',
        color: 'bg-sticker-yellow',
      },
    ],
  },
  {
    title: 'Jessie, 29, Melbourne',
    beforeImageUrl: JessieBefore,
    afterImageUrl: JessieAfter,
    goals: [
      {
        name: 'dullness',
        color: 'bg-sticker-blue',
      },
    ],
  },
];
