import {
  getConfig,
  useConsultationFlowConfig,
} from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';
import { ProblemType } from '@customer-frontend/graphql-types';
import { useStartQuizApplication } from '@customer-frontend/services';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

type AwaitingSurveyCardContentProps = {
  problemType: ProblemType;
  routes: {
    quiz: {
      root: (id: string) => string;
    };
  };
};

export const AwaitingSurveyCardContent = ({
  problemType,
  routes,
}: AwaitingSurveyCardContentProps): React.ReactElement => {
  const config = getConfig();
  const history = useHistory();
  const startQuizApplication = useStartQuizApplication();
  const consultConfig = useConsultationFlowConfig(problemType);

  const routeToQuiz = async (): Promise<void> => {
    const quizApplicationInfo = await startQuizApplication(problemType);
    return history.push(routes.quiz.root(quizApplicationInfo.id));
  };

  if (consultConfig?.isSyncExperience) {
    return (
      <div className="space-y-4">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Continue with your consult so your {isPrescriber, select, true {prescriber} other {practitioner}} can determine if our program is suitable for you."
            description="Description informing users to finish their consult"
            values={{
              isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>
        <Button isFullWidth onClick={routeToQuiz}>
          <FormattedMessage
            defaultMessage="Continue consult"
            description="Button that navigates to the quiz on profile"
          />
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className="mb-4 space-y-4">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Looks like you've almost finished the quiz. Just a few more questions!"
            description="Description informing users to finish a few more questions on their quiz"
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="A {isPrescriber, select, true {prescriber} other {practitioner}} will then assess your symptoms and prescribe you a treatment plan if they determine {brandTitle} is the right fit for you."
            description="Description informing users about the consult experience & expectations once they complete a quiz"
            values={{
              isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
              brandTitle: config.title,
            }}
          />
        </Typography>
      </div>
      <Button isFullWidth onClick={routeToQuiz}>
        <FormattedMessage
          defaultMessage="Continue quiz"
          description="Button that navigates to the quiz"
        />
      </Button>
    </>
  );
};
