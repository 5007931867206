import {
  Button,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { useMedicareDetailsQuery } from '@customer-frontend/graphql-types';
import { useHistory } from 'react-router-dom';

import type { MedicareDetailsProps } from './types';

export const MedicareDetails = ({
  styleConfig,
  linkToEdit = '/',
}: MedicareDetailsProps): JSX.Element => {
  const { loading, data } = useMedicareDetailsQuery({
    fetchPolicy: 'cache-first',
  });
  const history = useHistory();

  const hasMedicareDetails =
    !!data?.profile?.healthCareDetails?.hasMedicareDetails;

  if (loading) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="flex justify-between gap-4">
      <Typography size="medium-paragraph">
        {hasMedicareDetails
          ? 'Medicare number: •••• •••• •'
          : 'Add your Medicare details for your patient profile.'}
      </Typography>
      <Button
        level={styleConfig?.editButton?.level}
        palette={styleConfig?.editButton?.palette}
        size="sm"
        onClick={() => history.push(linkToEdit)}
      >
        {hasMedicareDetails ? 'Edit' : 'Add'}
      </Button>
    </div>
  );
};
